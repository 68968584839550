import React, { Component } from 'react';
import './BoardDetail.css'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../scripts/Translator';
import Checkbox from '../../../../components/checkbox/Checkbox';
import Api from '../../../scripts/Api';
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import Input from '../../../../components/input/Input';

class BoardDetail extends Component {

    constructor(props) {
        super(props);
        if (!props.member) {
            this.state = {
                id: null,
                job: "",
                since: "",
                name: "",
                public: false,
                image: null,
                email: ""
            };
        }
        else {
            this.state = {
                id: props.member.id,
                job: props.member.job,
                since: props.member.since,
                name: props.member.name,
                public: props.member.public,
                email: props.member.email
            };
        }
    }

    componentDidMount() {
        if (this.state.id) {
            // load image
            this.setState({imageLoading: true});
            var _this = this;
            Api.get("/board/"+this.state.id+"/image", response => {
                response.blob().then(data => {
                    var image = URL.createObjectURL(data);
                    _this.setState({image: image, imageLoading: false});
                });
            }, () => {
                _this.setState({imageLoading: false});
            });
        }
    }

    onValueChanged(key, e) {
        var o = {};
        o[key] = e.target.value;
        this.setState(o);
    } 

    onPublicChange() {
        this.setState({public: !this.state.public});
    }

    onImageChanged(e) {
        var image = URL.createObjectURL(e.target.files[0]);
        this.selectedImage = e.target.files[0];
        this.setState({image: image});
    }

    onCancel() {
        this.props.onBack();
    }

    onSave() {
        this.setState({loading: true});
        var body = new FormData();
        body.append("job", this.state.job);
        body.append("since", this.state.since);
        body.append("name", this.state.name);
        body.append("public", this.state.public);
        body.append("image", this.selectedImage);
        body.append("email", this.state.email);
        if (this.state.id) {
            Api.putForm("/board/"+this.state.id, body, () => {
                this.setState({loading: false});
                this.props.onMemberAdd();
            }, e => {
                this.setState({loading: false, error: e.error});
            });
        }
        else {
            Api.postForm("/board", body, () => {
                this.setState({loading: false});
                this.props.onMemberAdd();
            }, e => {
                this.setState({loading: false, error: e.error});
            });
        }
    }

    onDelete() {
        if (this.state.id) {
            Api.delete("/board/"+this.state.id, {}, () => {
                this.setState({loading: false});
                this.props.onMemberAdd();
            }, e => {
                this.setState({loading: false, error: e.error});
            });
        }
    }

    render() {
        return (
            <div className="board-detail">
                <NavigationBar title={Translator.get("board_detail_title")} buttonsLeft={
                    <div onClick={this.onCancel.bind(this)}>{Translator.get("cancel")}</div>
                } buttonsRight={
                    <div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>
                }/>
                {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="board-detail-content">
                    <div className="board-detail-image">
                        <img className="board-detail-image-object" src={this.state.image} alt=""/>
                        <input type="file" className="board-detail-image-input" 
                            accept="image/png,image/jpeg" 
                            onChange={this.onImageChanged.bind(this)}>
                        </input>
                    </div>
                    <Input type="text" className="board-detail-input" value={this.state.name} 
                        onChange={this.onValueChanged.bind(this, "name")}
                        label={Translator.get("name")}>
                    </Input>
                    <Input type="text" className="board-detail-input" value={this.state.since} 
                        onChange={this.onValueChanged.bind(this, "since")}
                        label={Translator.get("board_member_since")}>
                    </Input>
                    <Input type="text" className="board-detail-input" value={this.state.job} 
                        onChange={this.onValueChanged.bind(this, "job")}
                        label={Translator.get("job")}>
                    </Input>
                    <Input type="text" className="board-detail-input" value={this.state.email} 
                        onChange={this.onValueChanged.bind(this, "email")}
                        label={Translator.get("email")}>
                    </Input>
                    <div className="board-detail-checkbox-wrapper">
                        <Checkbox className="board-detail-checkbox" checked={this.state.public} onCheck={this.onPublicChange.bind(this)}/>
                        <span className="board-detail-checkbox-text">{Translator.get("display_on_webpage")}</span>
                    </div>
                    {this.state.id && 
                        <div onClick={this.onDelete.bind(this)} className="board-detail-remove">{Translator.get("delete")}</div>}
                </div>
            </div>
        );
    }

}

export default BoardDetail;