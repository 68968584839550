import React, { Component } from 'react';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../scripts/Translator';
import './UserDetail.css';
import Api from '../../../scripts/Api';
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import Dropdown from '../../../../components/dropdown/Dropdown';
import Input from '../../../../components/input/Input';

class UserDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {selectedRole: props.user.role.id, loading: true};
    }

    componentDidMount() {
        this.loadRoles(60*10);
        this.createAccessRightElements(this.props.user.role);
    }

    loadRoles(cache) {
        this.setState({loading: true});
        var _this = this;
        Api.get("/roles", body => {
            _this.accessControlExample = body.access_control;
            _this.roleElements = body.roles.map((role, i) => <option key={i} value={role.id}>{role.name}</option>);
            _this.roles = {};
            for (var role of body.roles) {
                _this.roles[role.id] = role;
            }
            _this.setState({loading: false});
        }, error => {
            _this.setState({error: error.error, loading: false});
        }, cache);
    }

    createAccessRightElements(role) {
        var accessRightElements = [];
        for (var right in role.access_control) {
            if (role.access_control[right]) {
                accessRightElements.push(<div key={right} className="user-detail-access-right-element">{Translator.get(right)}</div>);
            }            
        }
        this.setState({selectedRole: role.id, accessRightElements: accessRightElements});
    }

    onCancel() {
        this.props.onBack();
    }

    onSave() {
        var body = {
            username: this.props.user.username,
            role_id: this.state.selectedRole
        };
        this.setState({loading: true, error: null});
        var _this = this;
        Api.put("/users/"+this.props.user.username, body, () => {
            _this.setState({loading: false});
            _this.props.onUserSave();
        }, error => {
            _this.setState({loading: false, error: error.error});
        });
    }

    onRoleChange(e) {
        this.createAccessRightElements(this.roles[e.target.value]);
    }

    render() {
        var content;
        if (this.state.loading) {
            content = (<Popup><ActivityIndicator/></Popup>);
        }
        return (
            <div className="user-detail">
                <NavigationBar title={this.props.user.firstname.substring(0,1)+ ". " + this.props.user.lastname} buttonsRight={
                    <div onClick={this.onCancel.bind(this)}>{Translator.get("cancel")}</div>
                } buttonsLeft={
                    <div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>
                }/>
                <div className="user-detail-content">
                    {content}
                    <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                    <div className="user-detail-username">
                        <Input className="user-detail-info" value={this.props.user.username} onChange={() => {}} label={Translator.get("username")}>
                        </Input>
                    </div>
                    <div className="user-detail-role">
                        <Dropdown className="user-detail-info" 
                            value={this.state.selectedRole} 
                            onChange={this.onRoleChange.bind(this)}
                            label={Translator.get("role")}>
                            {this.roleElements}
                        </Dropdown>
                    </div>
                    <div className="user-detail-access-rights">
                        {this.state.accessRightElements}
                    </div>
                </div>
            </div>
        );
    }
}

export default UserDetail;