import React, { Component } from 'react';
import './FolderController.css'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../scripts/Translator';
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import AddIcon from '../../../../images/add.png';
import BackIcon from '../../../../images/back.png';
import FolderDetail from './FolderDetail';
import ListViewItem from '../../../../components/list-view/ListViewItem';
import Api from '../../../scripts/Api';

class FolderController extends Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "menu"
        };
    }

    componentDidMount() {
        this.loadFolders(60*15);
    }

    loadFolders(cache) {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.get("/files", body => {
            console.log(body);
            _this.folderElements = body.map((f, i) => <ListViewItem key={i} text={f.name} disclosureIndicator onClick={this.onFolderClicked.bind(this, f)}/>);
            _this.setState({loading: false});
        }, error => {
            _this.setState({error: error.error, loading: false});
        }, cache);
    }

    onFolderClicked(f) {
        this.selectedFolder = f;
        this.setState({view: "detail"});
    }

    onAddClick() {
        this.selectedFolder = null;
        this.setState({view: "detail"});
    }

    onBackClick() {
        this.props.onBack()
    }

    onChildBack() {
        this.setState({view: "menu"});
    }

    onFolderAdded() {
        this.loadFolders(0);
        this.onChildBack();
    }

    render() {
        if (this.state.view === "detail") {
            return <FolderDetail onBack={this.onChildBack.bind(this)} folder={this.selectedFolder} onFolderAdded={this.onFolderAdded.bind(this)}/>
        }
        return (
            <div className="folder-controller">
                <NavigationBar title={Translator.get("folders_title")} buttonsRight={
                    <img src={AddIcon} alt="Add Directory" onClick={this.onAddClick.bind(this)}></img>
                } buttonsLeft={
                    <img src={BackIcon} alt="Back" onClick={this.onBackClick.bind(this)}></img>
                }/>
                {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="folder-controller-content">
                    {this.folderElements}
                </div>
            </div>
        );
    }

}

export default FolderController;