import React, { Component } from 'react';
import './Popup.css';

class Popup extends Component {

    constructor(props) {
        super(props);
        if (this.props.children) {
            this.state = {popup: "open"};
        }
        else {
            this.state = {popup: "close"};
        }        
    }

    componentDidUpdate(prevProps) {
        if (this.props.children && prevProps.children !== this.props.children) {
            this.setState({popup: "open"});
        }
    }

    close() {
        this.setState({popup: "close"});
    }

    render() {
        if (this.state.popup !== "open") {
            return null;
        }
        var buttons;
        if (this.props.closeButton) {
            buttons = (
                <div className="popup-buttons">
                    <div className="popup-ok-button" onClick={this.close.bind(this)}>
                        {this.props.closeButton}
                    </div>
                </div>
            );
        }
        return (
            <div className="popup-overlay">
                <div className="popup-content">
                    <div className="popup-message">
                        {this.props.children}
                    </div>
                    {buttons}
                </div>
            </div>
        );
    }
}

export default Popup;