import React, { Component } from 'react';
import './Dropdown.css';

class Dropdown extends Component {

    render() {
        let propsClass = this.props.className || "";
        let appearance = this.props.appearance || "standard";
        let errorClass = this.props.error != null ? "error" : "";
        return (
            <div className={"dropdown-wrapper " + appearance + " " + propsClass + " " + errorClass}>
                <select className={"dropdown "}
                    value={this.props.value} 
                    onChange={this.props.onChange}>
                        {this.props.children}
                </select>
                <div className="dropdown-label">{this.props.label}</div>
            </div>
        );
    }
}

export default Dropdown;