import React, { Component } from 'react';
import './EventAddPredefined.css'
import Api from '../../scripts/Api';
import Translator from '../../scripts/Translator';
import Popup from '../../../components/popup/Popup';
import ActivityIndicator from '../../../components/activity-indicator/ActivityIndicator';
import NavigationBar from '../../../components/navigation-bar/NavigationBar';
import Checkbox from '../../../components/checkbox/Checkbox';
import BackIcon from '../../../images/back.png';

class EventAddPredefined extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedEvents: []
        };
    }

    componentDidMount() {
        var shouldLoad = 2;
        this.setState({loading: true, error: null});
        // load trainings
        Api.get("/teams/"+this.props.team.id+"/trainings", res => {
            this.trainings = res;
            shouldLoad--;
            if (shouldLoad === 0) {
                this.createEvents();
            }
        }, e => {
            this.setState({loading: false, error: e.error});
        }, 600);
        // load games
        Api.get("/public/teams/"+this.props.team.id+"/games", res => {
            this.games = res;
            shouldLoad--;
            if (shouldLoad === 0) {
                this.createEvents();
            }
        }, e => {
            this.setState({loading: false, error: e.error});
        }, 600);
    }

    createEvents() {
        // create initial trainings
        var trainings = [];
        var date = new Date();
        for (let i = 0; i < 7; i++) {
            // find all trainings with that weekday index
            for (let j = 0; j < this.trainings.length; j++) {
                if ((date.getDay()+6)%7 === Number(this.trainings[j].weekday)) {
                    trainings.push({next: new Date(date.getTime()), training: this.trainings[j]});
                }
            }
            date.setDate(date.getDate() + 1);
        }
        // create a list from the trainings and games
        var trainingIndex = 0;
        var gameIndex = 0;
        var events = [];
        var selected = [];
        while (trainings.length > 0) {
            let gameDate;
            // check if training or game is next
            if (gameIndex < this.games.length) {
                let gameDateSplitted = this.games[gameIndex].date.split(".");
                gameDate = new Date(gameDateSplitted[2]+"-"+gameDateSplitted[1]+"-"+gameDateSplitted[0]);
            }
            let trainingDate = trainings[(trainingIndex%trainings.length)].next;
            if (gameDate < trainingDate) {
                let game = this.games[gameIndex];
                let timeSplitted = game.time.split(":");
                events.push({
                    title: Translator.get("event_game_title"),
                    location: game.hall_name,
                    address: game.hall_name+", " + game.hall_address + ", " + game.hall_place,
                    date: gameDate.toISOString().substring(0, 10),
                    start_time: timeSplitted[0]+":"+timeSplitted[1],
                    end_time: (Number(timeSplitted[0])+2)+":"+timeSplitted[1],
                    deadline: 48,
                    description: game.team_home+" - "+game.team_away,
                    team_ids: [this.props.team.id]
                });
                gameIndex++;
            }
            else {
                let training = trainings[(trainingIndex%trainings.length)].training;
                events.push({
                    title: Translator.get("event_training_title"),
                    location: training.location.name,
                    address: "",
                    date: trainingDate.toISOString().substring(0, 10),
                    start_time: training.start_time,
                    end_time: training.end_time,
                    deadline: 48,
                    description: "",
                    team_ids: [this.props.team.id]
                });
                trainingDate.setDate(trainingDate.getDate() + 7);
                trainingIndex++;
            }
            selected.push(true);
            if (trainingDate.getMonth() > 3 && trainingDate.getFullYear() > new Date().getFullYear()) break;
        }
        this.eventElements = events.map(e => {
            var splitted = e.date.split("-");
            return <div className="event-add-predefined-event">
                <div className="event-add-predefined-row">
                    <div  className="event-add-predefined-row-title">{e.title}</div>
                    <div>{splitted[2] + "." + splitted[1] + "." + splitted[0]}</div>
                </div>
                <div className="event-add-predefined-row"><div>{e.location}</div><div>{e.start_time + " - " + e.end_time}</div></div>
            </div>;
        });
        this.events = events;
        this.setState({loading: false, selectedEvents: selected});
    }

    onToggleEvent(index) {
        var selected = this.state.selectedEvents;
        selected[index] = !selected[index];
        this.setState({selectedEvents: selected});
    }

    onCancel() {
        this.props.onBack();
    }

    onSave() {
        this.setState({loading: true});
        this.postEvents(this.events, 0);
    }

    postEvents(queue, index) {
        if (index >= queue.length) {
            this.setState({loading: false});
            this.props.onEventAdded();
            return;
        }
        if (!this.state.selectedEvents[index]) {
            this.postEvents(queue, index+1);
            return;
        }
        Api.post("/events", queue[index], () => {
            this.postEvents(queue, index+1);
        }, e => {
            console.log(e);
            this.setState({error: e.error, loading: false});
        });
    }

    render() {
        return <div className="event-add-predefined">
            <NavigationBar title={Translator.get("events_title")} buttonsLeft={
                <img onClick={this.onCancel.bind(this)} src={BackIcon} alt="back"></img>
            } buttonsRight={
                <div onClick={this.onSave.bind(this)}>{Translator.get("add")}</div>
            }/>
            <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
            {this.state.loading && <Popup><ActivityIndicator/></Popup>}
            <div className="event-add-predefined-content">
                {this.eventElements && this.eventElements.map((e, i) => {
                    return <div key={i} className="event-add-predefined-option"  onClick={this.onToggleEvent.bind(this, i)}>
                        {e}
                        <div className="event-add-predefined-option-checkbox">
                            <Checkbox checked={this.state.selectedEvents[i]}></Checkbox>
                        </div>                        
                    </div>
                })}
            </div>
        </div>;
    }

}

export default EventAddPredefined;