import React, { Component } from 'react';
import './EventDetailPopup.css'
import Popup from '../../../components/popup/Popup';
import AcceptIcon from '../../../images/event-accept.png';
import AcceptIconInactive from '../../../images/event-accept-inactive.png';
import DeclineIcon from '../../../images/event-decline.png';
import DeclineIconInactive from '../../../images/event-decline-inactive.png';
import Translator from '../../scripts/Translator';
import EventAskReasonPopup from './EventAskReasonPopup';
import Api from '../../scripts/Api';
import ActivityIndicator from '../../../components/activity-indicator/ActivityIndicator';

class EventDetailPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            askingReason: false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({error: null, askingReason: false});
        }        
    }

    acceptInvitation() {
        this.sendAnswer(1, "");
    }

    onCancelAsk() {
        this.setState({askingReason: false});
    }

    onSendReason(reason) {
        this.sendAnswer(0, reason);
    }

    sendAnswer(answer, comment) {
        this.setState({loading: true, error: null});
        Api.put("/events/"+this.props.event.id+"/answers/"+this.props.member.id, {answer: answer, comment: comment}, () => {
            this.setState({loading: false, answer: answer});
            this.props.onEventSaved();
        }, e => {
            this.setState({loading: false, error: e.error});
        });
        Api.invalidateCache("/events/"+this.props.event.id+"/answers");
    }

    render() {
        if (this.state.error) {
            return <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>;
        }
        if (this.state.loading) {
            return <Popup><ActivityIndicator/></Popup>;
        }
        if (this.state.askingReason) {
            return <EventAskReasonPopup onCancel={this.onCancelAsk.bind(this)} onSendReason={this.onSendReason.bind(this)}></EventAskReasonPopup>;
        }
        return <Popup closeButton={Translator.get("cancel")}>
            <div className="event-detail-popup-title">{this.props.event.title + " - " + this.props.member.name}</div>
            <div className="event-detail-answer">
                {this.props.answer.answer!==1 && <img src={AcceptIconInactive} alt="Accept Invitation" onClick={this.acceptInvitation.bind(this)}></img>}
                {this.props.answer.answer===1 && <img src={AcceptIcon} alt="Accept Invitation"></img>}
                <div></div>
                {this.props.answer.answer!==0 && <img src={DeclineIconInactive} alt="Decline Invitation" onClick={() => {this.setState({askingReason: true})}}></img>}                    
                {this.props.answer.answer===0 && <img src={DeclineIcon} alt="Decline Invitation"></img>}
            </div>
            <p>{this.props.answer.comment}</p>
        </Popup>;
    }

}

export default EventDetailPopup;