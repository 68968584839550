import React, { Component } from 'react';
import './TeamDetail.css'
import NavigationBar from '../../../components/navigation-bar/NavigationBar.js'
import Translator from '../../scripts/Translator';
import Checkbox from '../../../components/checkbox/Checkbox';
import Api from '../../scripts/Api';
import Popup from '../../../components/popup/Popup';
import ActivityIndicator from '../../../components/activity-indicator/ActivityIndicator';
import ListViewItem from '../../../components/list-view/ListViewItem';
import PlayerOverview from './players/PlayerOverview';

import BackIcon from '../../../images/back.png';
import CoachOverview from './coaches/CoachOverview';
import TrainingOverview from './trainings/TrainingOverview';
import Input from '../../../components/input/Input';

class TeamDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: null,
            loading: false,
            imageLoading: false,
            view: "overview"
        };
        if (!props.team) {
            this.state.teamName = Translator.get("new_team");
        }
        else {
            this.state.teamName = props.team.name;
            this.state.public = props.team.public;
            this.state.id = props.team.id;
            this.state.sv_id = props.team.sv_id;
            this.state.league = props.team.league;
            this.state.gender = props.team.gender;
            this.state.sv_group_id = props.team.sv_group_id;
        }
        this.imageChanged = false;
        this.canEdit = props.context.user.role.access_control.edit_all_teams || (props.context.user.role.access_control.edit_own_teams && props.team && props.team.is_coach);
    }

    componentDidMount() {
        if (this.state.id) {
            // load image
            this.setState({imageLoading: true});
            var _this = this;
            Api.get("/teams/"+this.state.id+"/image", response => {
                response.blob().then(data => {
                    var image = URL.createObjectURL(data);
                    _this.setState({image: image, imageLoading: false});
                });
            }, () => {
                _this.setState({imageLoading: false});
            });
        }
    }

    onImageChanged(e) {
        var image = URL.createObjectURL(e.target.files[0]);
        this.selectedImage = e.target.files[0];
        this.imageChanged = true;
        this.setState({image: image});
    }

    onNameChange(e) {
        if (!this.canEdit) return;
        this.setState({teamName: e.target.value});
    }

    onPublicChange() {
        if (!this.canEdit) return;
        this.setState({public: !this.state.public});
    }

    onCancel() {
        this.props.onBack();
    }

    onSave() {
        if (this.state.teamName === Translator.get("new_team")) {
            return;
        }
        this.setState({loading: true, error: null});
        var _this = this;
        if (this.state.id) {
            Api.put("/teams/"+this.state.id, {name: this.state.teamName, public: this.state.public, id: this.state.id, 
                    sv_id: this.state.sv_id, league: this.state.league, gender: this.state.gender, sv_group_id: this.state.sv_group_id}, () => {
                if (_this.imageChanged) {
                    var data = new FormData();
                    data.append("image", this.selectedImage);
                    data.append("id", this.state.id);
                    Api.putForm("/teams/"+this.state.id+"/image", data, () => {
                        _this.setState({loading: false});
                        _this.props.onTeamAdd();
                    }, error => {
                        _this.setState({loading: false, error: error.error});
                    });
                }
                else {
                    _this.setState({loading: false});
                    _this.props.onTeamAdd();
                }
            }, error => {
                _this.setState({loading: false, error: error.error});
            });
        }
        else {
            var data = new FormData();
            var pub = "false";
            if (this.state.public) {
                pub = "true";
            }
            data.append("name", this.state.teamName);
            data.append("public", pub);
            if (this.selectedImage) {
                data.append("image", this.selectedImage);
            }            
            Api.postForm("/teams", data, () => {
                _this.setState({loading: false});
                _this.props.onTeamAdd();
            }, error => {
                _this.setState({loading: false, error: error.error});
            });
        }        
    }

    onDelete() {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.delete("/teams/"+this.state.id, null, () => {
            _this.setState({loading: false});
            Api.invalidateCache("/teams");
            this.props.onBack();
        }, error => {
            _this.setState({error: error.error, loading: false});
        });
    }

    onEditPlayersClick() {
        this.setState({view: "player-detail"});
    }

    onEditCoachesClick() {
        this.setState({view: "coach-detail"});
    }

    onEditTrainingsClick() {
        this.setState({view: "trainings-detail"});
    }

    onChildBack() {
        this.setState({view: "overview"});
    }

    render() {
        if (this.state.view === "player-detail"){
            return <PlayerOverview context={this.props.context} canEdit={this.canEdit} teamName={this.state.teamName} id={this.state.id} onBack={this.onChildBack.bind(this)}></PlayerOverview>
        }
        if (this.state.view === "coach-detail"){
            return <CoachOverview context={this.props.context} canEdit={this.canEdit} teamName={this.state.teamName} id={this.state.id} onBack={this.onChildBack.bind(this)}></CoachOverview>
        }
        if (this.state.view === "trainings-detail") {
            return <TrainingOverview canEdit={this.canEdit} teamName={this.state.teamName} id={this.state.id} onBack={this.onChildBack.bind(this)}></TrainingOverview>;
        }
        var content;
        if (this.state.loading) {
            content = (<Popup><ActivityIndicator/></Popup>);
        }
        var buttonLeft, buttonRight;
        if (this.canEdit) {
            buttonRight = <div onClick={this.onCancel.bind(this)}>{Translator.get("cancel")}</div>;
            buttonLeft = <div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>;
        }
        else {
            buttonLeft = <img src={BackIcon} alt="Back" onClick={this.onCancel.bind(this)}></img>
        }
        return (
            <div className="team-detail">
                <NavigationBar title={this.state.teamName} buttonsRight={buttonRight} buttonsLeft={buttonLeft}/>
                {content}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="team-detail-image-wrapper">                    
                    <img className="team-detail-image" alt="" src={this.state.image}></img>
                    {this.canEdit && <input className="team-detail-image-input" type="file" accept="image/png,image/jpeg" onChange={this.onImageChanged.bind(this)}></input>}
                    {this.state.imageLoading && <ActivityIndicator className="team-detail-image-loader"/>}
                </div>
                <Input type="text" className="team-detail-name-input" 
                    value={this.state.teamName} 
                    onChange={this.onNameChange.bind(this)} 
                    label={Translator.get("team_name_label")}>
                </Input>
                <div className="team-detail-public-checkbox">
                    <Checkbox className="team-detail-public-checkbox-checkbox" checked={this.state.public} onCheck={this.onPublicChange.bind(this)}/>
                    <span className="team-detail-public-checkbox-text">{Translator.get("display_on_webpage")}</span>
                </div>                
                {this.state.id && 
                    <div className="team-detail-edit-options">
                        <ListViewItem text={Translator.get("players_title")} disclosureIndicator onClick={this.onEditPlayersClick.bind(this)}/>
                        <ListViewItem text={Translator.get("coaches_title")} disclosureIndicator onClick={this.onEditCoachesClick.bind(this)}/>
                        <ListViewItem text={Translator.get("trainings_title")} disclosureIndicator onClick={this.onEditTrainingsClick.bind(this)}/>
                    </div>
                }
                {this.state.id && this.canEdit && 
                    <div onClick={this.onDelete.bind(this)} className="team-remove">{Translator.get("team_delete_button")}</div>}
            </div>
        );
    }
}

export default TeamDetail;