import React, { Component } from 'react';
import './CoachOverview.css'
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../scripts/Translator';

import AddIcon from '../../../../images/add.png'
import BackIcon from '../../../../images/back.png';
import PlayerCell from '../players/PlayerCell';
import Api from '../../../scripts/Api';
import PlayerEdit from '../players/PlayerEdit';
import CoachSearch from './CoachSearch';

class CoachOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            view: "overview"
        };
        this.canEdit = props.canEdit;
    }

    componentDidMount() {
        this.loadCoaches(60 * 10);
    }

    loadCoaches(cache) {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.get("/teams/"+this.props.id+"/coaches", members => {    
            _this.memberCells = members.map((m, i) => <PlayerCell key={i} member={m} onClick={this.onMemberClick.bind(this, m)}></PlayerCell>);
            _this.setState({loading: false});
        }, error => {
            _this.setState({error: error.error, loading: false});
        }, cache);
    }

    onMemberClick(m) {
        this.selectedMember = m;
        this.setState({view: "view-player"});
    }

    onAddClick() {
        this.setState({view: "add-player"})
    }

    onBackClick() {
        this.props.onBack();
    }

    onChildBack() {
        this.loadCoaches(0);
        this.setState({view: "overview"});
    }

    onMemberDetailCancel() {
        this.selectedMember = null;
        this.setState({view: "overview"});
    }

    onMemberSave(m) {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.put("/members/"+m.id, m, () => {
            _this.loadCoaches(0);
            _this.setState({view: "overview", loading: false});
        }, e => {
            _this.setState({error: e.error, loading: false});
        });
    }

    onMemberResign(mem_id, rel_id) {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.delete("/teams/"+this.props.id+"/coaches/"+mem_id, {}, () => {
            _this.setState({view: "overview", loading: false});
            _this.loadCoaches(0);
        }, error => {
            _this.setState({error: error.error, loading: false});
        });
    }

    render() {
        var leftButton, rightButton;
        if (this.canEdit) {
            leftButton = Translator.get("cancel");
            rightButton = Translator.get("save");
        }
        else {
            leftButton = <img className="view-player-back-button" src={BackIcon} alt="Back" onClick={this.onMemberDetailCancel.bind(this)}></img>;
        }
        if (this.state.view === "view-player") {
            return (
                <div>
                    <PlayerEdit member={this.selectedMember} backButton={leftButton} saveButton={rightButton} 
                        onBack={this.onMemberDetailCancel.bind(this)} onSave={this.onMemberSave.bind(this)} 
                        canRemove={this.canEdit}
                        onResign={this.onMemberResign.bind(this)} static={this.selectedMember.has_user||!this.canEdit}
                        resignTitle={Translator.get("remove")}></PlayerEdit>
                    {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                    <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                </div>
            );
        }
        if (this.state.view === "add-player") {
            return <CoachSearch teamID={this.props.id} onBack={this.onChildBack.bind(this)}></CoachSearch>
        }
        var content;
        if (this.state.loading) {
            content = (<Popup><ActivityIndicator/></Popup>);
        }
        return (
            <div className="player-overview">
                <NavigationBar title={this.props.teamName} buttonsRight={
                    this.canEdit && <img src={AddIcon} alt="Add Team Button" onClick={this.onAddClick.bind(this)}></img>
                } buttonsLeft={
                    <img src={BackIcon} alt="Back" onClick={this.onBackClick.bind(this)}></img>
                }/>
                {content}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="player-overview-content">
                    {this.memberCells}
                </div>
            </div>
        );
    }
}

export default CoachOverview;