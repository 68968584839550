import React, { Component } from 'react';
import BackIcon from '../../../../images/back.png'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import ListViewItem from '../../../../components/list-view/ListViewItem';
import Translator from '../../../scripts/Translator';
import Leagues from './leagues/Leagues'
import GamesController from './games/GamesController';
import './SeasonController.css';

class SeasonController extends Component {

    constructor(props) {
        super(props);
        this.state = {view: "menu"};
    }

    onBack() {
        this.props.onBack();
    }

    onChildBack() {
        this.setState({view: "menu"});
    }

    onTeamMappingClick() {
        this.setState({view: "mapping"});
    }

    onGameDataClick() {
        this.setState({view: "games"});
    }

    render() {
        if (this.state.view === "menu") {
            return (
                <div className="season-controller">
                    <NavigationBar title={Translator.get("season_title")} buttonsLeft={
                        <img src={BackIcon} alt="Back" onClick={this.onBack.bind(this)}></img>
                    }/>
                    <div className="club-controller-list-view">
                        <ListViewItem onClick={this.onTeamMappingClick.bind(this)} text={Translator.get("team_mapping_title")} disclosureIndicator/>
                        <ListViewItem onClick={this.onGameDataClick.bind(this)} text={Translator.get("game_data_title")} disclosureIndicator/>
                    </div>
                </div>            
            );
        }
        else if (this.state.view === "mapping") {
            return (<Leagues onBack={this.onChildBack.bind(this)}></Leagues>);
        }
        else if (this.state.view === "games") {
            return (<GamesController onBack={this.onChildBack.bind(this)}></GamesController>);
        }
    }

}

export default SeasonController;