import React, { Component } from 'react';
import TabController from '../../components/tab-controller/TabController.js'
import Protocol from './protocol/Protocol.js'
import TeamsController from './teams/TeamsController.js'
import SecurityController from './security/SecurityController.js'

import teamIcon from '../../images/team.png'
import teamIconInactive from '../../images/team-inactive.png'
import protocolIcon from '../../images/protocol.png'
import protocolIconInactive from '../../images/protocol-inactive.png'
import securityIcon from '../../images/security.png'
import securityIconInactive from '../../images/security-inactive.png'
import Translator from '../scripts/Translator.js';
import clubIcon from '../../images/club.png'
import clubIconInactive from '../../images/club-inactive.png'
import ClubController from './club/ClubController.js';
import profileIcon from '../../images/profile.png'
import profileIconInactive from '../../images/profile-inactive.png'
import Profile from './profile/Profile.js';
import eventIcon from './../../images/event.png'
import eventIconInactive from './../../images/event-inactive.png'
import EventController from './events/EventController.js';


class RootController extends Component {

    constructor(props) {
        super(props);
        this.state = {image: props.iconInactive};
        this.init();
    }

    init() {
        this.tabs = [];
        this.views = [];
        var ac = this.props.context.user.role.access_control;
        if (ac.view_all_teams || ac.view_own_teams) {
            this.tabs.push({title: Translator.get("teams_title"), key: "teams", icon: teamIcon, iconInactive: teamIconInactive});
            this.views.push(<TeamsController key="teams" name="teams" canAdd={ac.edit_all_teams}  context={this.props.context}/>);
        }
        if (ac.view_all_events || ac.view_own_events) {
            this.tabs.push({title: Translator.get("events_title"), key: "events", icon: eventIcon, iconInactive: eventIconInactive});
            this.views.push(<EventController key="events" name="events" context={this.props.context}/>);
        }
        if (ac.view_protocol) {
            this.tabs.push({title: Translator.get("protocol_title"), key: "protocol", icon: protocolIcon, iconInactive: protocolIconInactive});
            this.views.push(<Protocol key="protocol" name="protocol"/>);
        }
        if (ac.edit_roles || ac.edit_users) {
            this.tabs.push({title: Translator.get("security_title"), key: "security", icon: securityIcon, iconInactive: securityIconInactive});
            this.views.push(<SecurityController key="security" name="security" context={this.props.context}/>);
        }
        if (ac.edit_locations || ac.view_all_teams || ac.edit_all_teams) {
            this.tabs.push({title: Translator.get("club_title"), key: "club", icon: clubIcon, iconInactive: clubIconInactive});
            this.views.push(<ClubController key="club" name="club" context={this.props.context}/>);
        }
        this.tabs.push({title: Translator.get("profile_title"), key: "profile", icon: profileIcon, iconInactive: profileIconInactive});
        this.views.push(<Profile key="profile" name="profile" context={this.props.context}/>);
    }

    render() {
        return (
            <TabController 
                default={this.tabs[0].key}
                tabs={this.tabs}>
                {this.views}
            </TabController>
        );
    }
}

export default RootController;