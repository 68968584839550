import React, { Component } from 'react';
import './BoardController.css'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../scripts/Translator';
import AddIcon from '../../../../images/add.png';
import BackIcon from '../../../../images/back.png';
import BoardDetail from './BoardDetail';
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import ListViewItem from '../../../../components/list-view/ListViewItem';
import Api from '../../../scripts/Api';

class BoardController extends Component {

    constructor(props) {
        super(props);
        this.state = {view: "menu"};
    }

    componentDidMount() {
        this.loadMembers(60*10);
    }

    loadMembers(cache) {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.get("/board", body => {
            console.log(body);
            _this.boardElements = body.map((b, i) => <ListViewItem key={i} text={b.job} disclosureIndicator onClick={this.onBoardMemberClick.bind(this, b)}/>);
            _this.setState({loading: false});
        }, error => {
            _this.setState({error: error.error, loading: false});
        }, cache);
    }

    onBoardMemberClick(bm) {
        this.selectedMember = bm;
        this.setState({view: "detail"});
    }

    onBackClick() {
        this.props.onBack();
    }

    onChildBack() {
        this.setState({view: "menu"});
    }

    onAddClick() {
        this.selectedMember = null;
        this.setState({view: "detail"});
    }

    onMemberAdd() {
        this.loadMembers(0);
        this.setState({view: "menu"});
    }

    render() {
        if (this.state.view === "detail") {
            return <BoardDetail member={this.selectedMember} onBack={this.onChildBack.bind(this)} onMemberAdd={this.onMemberAdd.bind(this)}></BoardDetail>
        }
        return (
            <div className="board-controller">
                <NavigationBar title={Translator.get("board_title")} buttonsRight={
                    <img src={AddIcon} alt="Add Role" onClick={this.onAddClick.bind(this)}></img>
                } buttonsLeft={
                    <img src={BackIcon} alt="Back" onClick={this.onBackClick.bind(this)}></img>
                }/>
                {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="board-controller-content">
                    {this.boardElements}
                </div>
            </div>
        );
    }

}

export default BoardController;