import React, { Component } from 'react';
import './TrainingDetail.css'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../scripts/Translator';

import BackIcon from '../../../../images/back.png';
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import Api from '../../../scripts/Api';
import Input from '../../../../components/input/Input';
import Dropdown from '../../../../components/dropdown/Dropdown';

class TrainingDetail extends Component {

    constructor(props) {
        super(props);
        var training = props.training || {};
        this.state = {
            id: training.id || null,
            locationIndex: 0,
            weekdayIndex: training.weekday || 0,
            startTime: training.start_time || "",
            endTime: training.end_time || ""
        };
        this.canEdit = props.canEdit;
    }

    componentDidMount() {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.get("/locations", body => {    
            _this.locations = body;
            _this.locationElements = body.map((l, i) => <option key={i} value={i}>{l.name}</option>);
            var index = _this.state.locationIndex;
            for (var j = 0; j < body.length && _this.props.training; j++) {
                if (body[j].id === _this.props.training.location.id) {
                    index = j;
                    break;
                }
            }
            _this.setState({loading: false, locationIndex: index});
        }, error => {
            _this.setState({error: error.error, loading: false});
        }, 60);
    }

    onSave() {
        var body = {
            location_id: Number(this.locations[this.state.locationIndex].id),
            weekday: Number(this.state.weekdayIndex),
            start_time: this.state.startTime,
            end_time: this.state.endTime
        };
        this.setState({loading: true, error: null});
        var _this = this;
        if (this.state.id) {
            Api.put("/teams/"+this.props.teamID+"/trainings/"+this.state.id, body, () => {
                _this.props.onTrainingSaved();
            }, e => {
                _this.setState({error: e.error, loading: false});
            });
        }
        else {
            Api.post("/teams/"+this.props.teamID+"/trainings", body, () => {
                _this.props.onTrainingSaved();
            }, e => {
                _this.setState({error: e.error, loading: false});
            });
        }        
    }

    onDelete() {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.delete("/teams/"+this.props.teamID+"/trainings/"+this.state.id, {}, () => {
            _this.props.onTrainingSaved();
        }, e => {
            _this.setState({error: e.error, loading: false});
        });
    }

    onCancel() {
        this.props.onBack();
    }

    onLocationChanged(e) {
        this.setState({locationIndex: e.target.value});
    }

    onWeekdayChanged(e) {
        this.setState({weekdayIndex: e.target.value});
    }

    onStartTimeChanged(e) {
        this.setState({startTime: e.target.value});
    }

    onEndTimeChanged(e) {
        this.setState({endTime: e.target.value});
    }

    render() {
        var leftButton, rightButton;
        if (this.canEdit) {
            rightButton = <div onClick={this.onCancel.bind(this)}>{Translator.get("cancel")}</div>;
            leftButton = <div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>;
        }
        else {
            leftButton = <img className="training-detail-back-button" src={BackIcon} alt="Back" onClick={this.onCancel.bind(this)}></img>;
        }
        return (
            <div className="training-detail">
                <NavigationBar title={Translator.get("training_title")} buttonsRight={rightButton} buttonsLeft={leftButton}/>
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                <div className="training-detail-content">
                    <Dropdown className="training-detail-input" 
                        value={this.state.locationIndex} 
                        onChange={this.onLocationChanged.bind(this)}
                        label={Translator.get("location")}>
                        {this.locationElements}
                    </Dropdown>
                    <Dropdown className="training-detail-input" value={this.state.weekdayIndex} 
                        onChange={this.onWeekdayChanged.bind(this)} label={Translator.get("weekday")}>
                        <option value={0}>{Translator.get("monday")}</option>
                        <option value={1}>{Translator.get("tuesday")}</option>
                        <option value={2}>{Translator.get("wednsday")}</option>
                        <option value={3}>{Translator.get("thursday")}</option>
                        <option value={4}>{Translator.get("friday")}</option>
                        <option value={5}>{Translator.get("saturday")}</option>
                        <option value={6}>{Translator.get("sunday")}</option>
                    </Dropdown>
                    <Input type="time" className="training-detail-input"
                        value={this.state.startTime} 
                        onChange={this.onStartTimeChanged.bind(this)}
                        label={Translator.get("start_time")}>
                    </Input>
                    <Input type="time" className="training-detail-input"
                        value={this.state.endTime} 
                        onChange={this.onEndTimeChanged.bind(this)}
                        label={Translator.get("end_time")}>
                    </Input>
                    {this.state.id && this.canEdit && <div onClick={this.onDelete.bind(this)} className="training-detail-remove">{Translator.get("delete")}</div>}
                </div>
            </div>
        );
    }

}

export default TrainingDetail;