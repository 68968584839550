import React, { Component } from 'react';
import './UserVerify.css'
import Api from '../scripts/Api';
import VolleyLogo from "../../images/volley-logo-black.png"
import Translator from '../scripts/Translator';

class UserVerify extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let _this = this;
        let query = window.location.search.substr(1, 100);
        let terms = query.split("&");
        let map = {};
        terms.forEach(s => {
            console.log(s);
            let split = s.split("=");
            map[split[0]] = split[1];
        });
        Api.get("/verify", (response) => {
            _this.setState({first_name: response.firstname});
        }, (e) => {
            _this.setState({error: e.error});
        }, 0, map);
    }

    render() {
        if (this.state.error) {
            return <div className="member-request-wrapper">
                <div className="member-request">
                    <img className="member-request-logo" src={VolleyLogo} alt=""></img>
                    <h2 className="member-request-title">Konto Aktivierung</h2>
                    <p className="member-request-confirmation">{Translator.get(this.state.error)}</p>
                </div>
            </div>;
        }
        return <div className="member-request-wrapper">
                <div className="member-request">
                    <img className="member-request-logo" src={VolleyLogo} alt=""></img>
                    <h2 className="member-request-title">Konto Aktivierung</h2>
                    <p className="member-request-confirmation">Danke, {this.state.first_name}, dein Konto wurde aktiviert, du kannst dich nun anmelden.</p>
                </div>
            </div>;
    }

}

export default UserVerify;