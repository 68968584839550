import React, { Component } from 'react';
import './ActivityIndicator.css';

import loader from '../../images/loader.png'

class ActivityIndicator extends Component {

    render() {
        return (
            <img className={"activity-indicator "+this.props.className} src={loader} alt=""></img>
        );
    }
}

export default ActivityIndicator;