import React, { Component } from 'react';
import './MemberRequestDetail.css'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar.js'
import Translator from '../../../scripts/Translator';
import BackIcon from '../../../../images/back.png';
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import Api from '../../../scripts/Api';
import MemberRequestAccept from './MemberRequestAccept';

class MemberRequestDetail extends Component {

    constructor() {
        super();
        this.state = {
            view: "overview",
            loading: false,
            error: null
        };
    }

    onChildBack() {
        this.setState({view: "overview"});
    }

    onBack() {
        this.props.onBack();
    }

    onAcceptRequest() {
        this.setState({view: "add"})
    }

    onDelete() {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.delete("/member-requests/"+this.props.member.id, {}, () => {
            _this.props.onMemberRequestChanged();
        }, e => {
            _this.setState({error: e.error, loading: false});
        });
    }

    onMemberRequestChanged() {
        this.props.onMemberRequestChanged();
    }

    render() {
        if (this.state.view === "add") {
            return <MemberRequestAccept onBack={this.onChildBack.bind(this)} member={this.props.member} onMemberRequestChanged={this.onMemberRequestChanged.bind(this)}></MemberRequestAccept>
        }
        return (
            <div className="member-request-detail">
                <NavigationBar title={Translator.get("member_request_title")} buttonsLeft={ <img src={BackIcon} alt="Back" onClick={this.onBack.bind(this)}></img>} />
                <div className="member-request-detail-content">
                    <div className="member-request-detail-buttons">
                        <div className="member-request-detail-add-button" onClick={this.onAcceptRequest.bind(this)}>{Translator.get("accept_member_request_title")}</div>
                        <div className="member-request-detail-delete-button" onClick={this.onDelete.bind(this)}>{Translator.get("delete")}</div>
                    </div>
                    {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                    <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                    <div className="member-request-detail-label">{Translator.get("first_name")}</div>
                    <div className="member-request-detail-input">{this.props.member.first_name}</div>
                    <div className="member-request-detail-label">{Translator.get("last_name")}</div>
                    <div className="member-request-detail-input">{this.props.member.last_name}</div>
                    <div className="member-request-detail-label">{Translator.get("email")}</div>
                    <div className="member-request-detail-input">{this.props.member.email}</div>
                    <div className="member-request-detail-label">{Translator.get("phone_number")}</div>
                    <div className="member-request-detail-input">{this.props.member.phone_number}</div>
                    <div className="member-request-detail-label">{Translator.get("birthdate")}</div>
                    <div className="member-request-detail-input">{this.props.member.birthdate}</div>
                    <div className="member-request-detail-label">{Translator.get("address")}</div>
                    <div className="member-request-detail-input">{this.props.member.address}</div>
                    <div className="member-request-detail-label">{Translator.get("zip_code")}</div>
                    <div className="member-request-detail-input">{this.props.member.zip_code}</div>
                    <div className="member-request-detail-label">{Translator.get("locality")}</div>
                    <div className="member-request-detail-input">{this.props.member.locality}</div>
                </div>
            </div>            
        );
    }
}

export default MemberRequestDetail;