import React, { Component } from 'react';
import './ListViewItem.css';

import RightArrow from '../../images/right-arrow.png'

class ListViewItem extends Component {

    onClick(e) {
        if (this.props.onClick) {
            this.props.onClick(e);
        }        
    }

    render() {
        return (            
            <div className="list-view-item" onClick={this.onClick.bind(this)}>
                <div className="list-view-item-text">
                    {this.props.text}
                </div>
                <div className="list-view-item-widgets">
                    {this.props.badgeNumber>0 && <div className="list-view-item-notification-badge">
                        {this.props.badgeNumber}
                    </div>
                    }
                    {this.props.disclosureIndicator && 
                        <img className="list-view-item-image" alt="" src={RightArrow}></img>
                    }
                </div>                                    
            </div>                
        );
    }
}

export default ListViewItem;