import React, { Component } from 'react';
import './UserGuideStyles.css'
import TeamDetail from '../../images/ug-team-detail.png'
import PlayerOverview from '../../images/ug-player-overview.png'
import PlayerSearch from '../../images/ug-player-search.png'
import PlayerDetail from '../../images/ug-player-detail.png'

class UserGuideAddPlayer extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <div>
            <h1 className="user-guide-title">Spieler hinzufügen</h1>
            <p className="user-guide-warning">Du kannst nur denjenigen Teams Spieler hinzufügen bei welchen du als Trainer eingetragen bist.</p>
            <h2 className="user-guide-section">Schritt 1: Team auswählen</h2>
            <p>Öffne die Webapp und klicke unten auf den "Teams" Tab. Wähle das Team welchem du einen Spieler hinzufügen möchtest. Wähle dann "Spieler bearbeiten".</p>
            <img className="user-guide-image-mobile" src={TeamDetail} alt="Team Detailansicht"></img>
            <div className="user-guide-image-description">Team Detailansicht</div>

            <h2 className="user-guide-section">Schritt 2: Spieler suchen</h2>
            <p>Klicke oben rechts auf das Plus.</p>
            <img className="user-guide-image-mobile" src={PlayerOverview} alt="Spielerübersicht"></img>
            <div className="user-guide-image-description">Spielerübersicht</div>
            <p>Suche nach dem Namen des Spielers in der Datenbank. Dadurch sollen duplikate vermieden werden. Falls der gewünschte Spieler gefunden wird klicke auf ihn, ansonsten
                wähle "Neuer Spieler". </p>
            <img className="user-guide-image-mobile" src={PlayerSearch} alt="Spielersuche"></img>
            <div className="user-guide-image-description">Spielersuche</div>

            <h2 className="user-guide-section">Schritt 3: Spieler erfassen</h2>
            <p>Überprüfe und ergänze die Daten. Danach wähle "Hinzufügen" oben rechts.</p>
            <img className="user-guide-image-mobile" src={PlayerDetail} alt="Ansicht der Spielerdaten"></img>
            <div className="user-guide-image-description">Ansicht der Spielerdaten</div>
        </div>;
    }

}

export default UserGuideAddPlayer;