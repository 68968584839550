import React, { Component } from 'react';
import './Leagues.css'
import NavigationBar from '../../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../../scripts/Translator';
import Api from '../../../../scripts/Api';
import Popup from '../../../../../components/popup/Popup';
import ActivityIndicator from '../../../../../components/activity-indicator/ActivityIndicator';

class Leagues extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.svTeams = [];
        this.teams = [];
        this.invalidTeams = [];
    }

    componentDidMount() {
        var _this = this;
        this.setState({loading: true, error: null});
        Api.get("/swiss-volley/teams", b => {
            _this.svTeams = b;
            Api.get("/teams", ts => {
                _this.teams = ts;
                var mapping = {};
                for (var i = 0; i < b.length; i++) {
                    for (var j = 0; j < ts.length; j++) {
                        if (b[i].sv_id === ts[j].sv_id) {
                            mapping[b[i].sv_id] = j;
                        }
                    }
                }
                // get invalid mappings from old seasons
                for (i = 0; i < ts.length; i++) {
                    if (ts[i].sv_id !== "0" && !mapping[ts[i].sv_id]) {
                        _this.invalidTeams.push(ts[i]);
                    }
                }
                _this.setState(mapping);
                _this.setState({loading: false});
            }, e => {
                _this.setState({loading: false, error: e.error});
            }, 600);
        }, e => {
            _this.setState({loading: false, error: e.error});
        }, 600);
    }

    onBack() {
        this.props.onBack();
    }

    onSave() {
        this.setState({loading: true, error: null});
        var _this = this;
        for (var i = 0; i < this.invalidTeams.length; i++) {
            this.invalidTeams[i].sv_id = "0";
            Api.put("/teams/"+this.invalidTeams[i].id, this.invalidTeams[i]);
        }
        var success = 0;
        var onSuccess = () => {
            success++;
            if (success === this.svTeams.length) {
                _this.setState({loading: false});
                _this.props.onBack();
            }
        };
        var onError = e => {
            _this.setState({loading: false, error: e.error});
        };
        for (i = 0; i < this.svTeams.length; i++) {
            var team = this.teams[this.state[this.svTeams[i].sv_id]];
            if (!team) {
                success++;
                continue;
            }
            team.sv_id = this.svTeams[i].sv_id;
            team.gender = this.svTeams[i].gender;
            team.league = this.svTeams[i].league;
            team.sv_group_id = this.svTeams[i].group_id;
            Api.put("/teams/"+team.id, team, onSuccess, onError);
        }
        if (success === this.svTeams.length) {
            _this.setState({loading: false});
            _this.props.onBack();
        }
    }

    onTeamChanged(id, e) {
        var s = {};
        s[id] = e.target.value;
        this.setState(s);
    }

    render() {
        var teamElements = this.svTeams.map((t, i) => 
            <div key={i}>
                <div className="league-label" >
                    {t.league}
                </div>
                {<select className="league-input" value={this.state[t.sv_id] || 0} onChange={this.onTeamChanged.bind(this, t.sv_id)}>
                    {this.teams.map((t, i) => <option value={i} key={i}>{t.name}</option>)}
                </select>}
            </div>);
        return (
            <div className="league">
                <NavigationBar title={Translator.get("team_mapping_title")} buttonsRight={
                    <div onClick={this.onBack.bind(this)}>{Translator.get("cancel")}</div>
                } buttonsLeft={
                    <div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>
                }/>
                {this.state.loading && <Popup><ActivityIndicator></ActivityIndicator></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="league-content">
                    {teamElements}
                </div>
            </div>
        );
    }

}

export default Leagues;