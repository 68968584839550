import React, { Component } from 'react';
import './UsersController.css';
import BackIcon from '../../../../images/back.png';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../scripts/Translator';
import Api from '../../../scripts/Api';
import ListViewItem from '../../../../components/list-view/ListViewItem.js';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import Popup from '../../../../components/popup/Popup';
import UserDetail from './UserDetail';

class UsersController extends Component {

    constructor(props) {
        super(props);
        this.state = {loading: false, view: "menu"};
    }

    componentDidMount() {
        this.loadUsers(60*10)
    }

    loadUsers(cache) {
        this.setState({loading: true});
        var _this = this;
        Api.get("/users", body => {
            console.log(body)
            _this.userElements = body.map((user, i) => 
                <ListViewItem key={i} text={user.firstname + " " + user.lastname} disclosureIndicator onClick={_this.onUserClick.bind(_this, user)}/>);
            _this.setState({loading: false});
        }, error => {
            _this.setState({error: error.error, loading: false});
        }, cache);
    }

    onBack() {
        // child requests exit
        this.setState({view: "menu"});
    }

    onBackClick() {
        this.props.onBack();
    }

    onUserClick(user) {
        this.selectedUser = user;
        this.setState({view: "user-detail"});
    }

    onUserSave() {
        this.loadUsers(0);
        this.setState({view: "menu"});
    }

    render() {
        if (this.state.view === "user-detail") {
            //return <RoleDetail role={this.selectedRole} onBack={this.onBack.bind(this)} onRoleAdd={this.onRoleAdd.bind(this)}/>;
            return <UserDetail user={this.selectedUser} onBack={this.onBack.bind(this)} onUserSave={this.onUserSave.bind(this)}/>
        }
        var content;
        if (this.state.error) {
            content = <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>;
        }
        else if (this.state.loading) {
            content =  <ActivityIndicator className="users-controller-spinner"/>;
        }
        else {
            content = (
                <div className="users-controller-list-view">
                    {this.userElements}
                </div>
            );
        }
        return (
            <div>
                <NavigationBar title={Translator.get("users_title")} buttonsLeft={
                    <img src={BackIcon} alt="Back" onClick={this.onBackClick.bind(this)}></img>
                }/>
                {content}
            </div>
        );
    }
}

export default UsersController;