import React, { Component } from 'react';
import './TabItem.css';

class TabItem extends Component {

    onClick() {
        this.props.onTabSelected(this);
    }

    render() {
        if (this.props.selected) {
            return (
                <div className="tab-item-tab selected" onClick={this.onClick.bind(this)}>
                    <img className="tab-item-image" src={this.props.icon} alt={this.props.title+"-tab"}></img>
                    <div className="tab-item-text">{this.props.title}</div>
                </div>
            );
        }
        return (
            <div className="tab-item-tab" onClick={this.onClick.bind(this)}>
                <img className="tab-item-image" src={this.props.iconInactive} alt={this.props.title+"-tab"}></img>
                <div className="tab-item-text">{this.props.title}</div>
            </div>
        );
    }
}

export default TabItem;