import React, { Component } from 'react';
import './UserGuideStyles.css'
import AndroidLogin from '../../images/ug-android-login.jpg'
import AndroidContextMenu from '../../images/ug-android-context-menu.jpg'
import AndroidHomescreen from '../../images/ug-android-add-homescreen.jpg'
import iOSLogin from '../../images/ug-ios-login.png'
import iOSContextMenu from '../../images/ug-ios-context-menu.png'
import iOSHomescreen from '../../images/ug-ios-add-homescreen.png'

class UserGuideInstallation extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <div>
            <h1 className="user-guide-title">Installation</h1>
            <h2 className="user-guide-section">Android</h2>
            <p>Öffne die Webapp und klicke auf die drei Punkte oben rechts in der Ecke.</p>
            <img className="user-guide-image-mobile" src={AndroidLogin} alt="Android Startbildschirm"></img>
            <div className="user-guide-image-description">Android Startbildschirm</div>
            <p>Im erschienenen Menü wähle die "Zum Homescreen hinzufügen" option.</p>
            <img className="user-guide-image-mobile" src={AndroidContextMenu} alt="Android Optionen"></img>
            <div className="user-guide-image-description">Android Optionen</div>
            <p>Nun wähle "Hinzufügen" und die App erscheint auf dem Home Bildschirm.</p>
            <img className="user-guide-image-mobile" src={AndroidHomescreen} alt="Android Homescreen"></img>
            <div className="user-guide-image-description">Android zu Homescreen hinzufügen</div>

            <h2 className="user-guide-section">iOS</h2>
            <p>Öffne die Webapp und klicke auf den eingekreisten Knopf unten in der Mitte.</p>
            <img className="user-guide-image-mobile" src={iOSLogin} alt="iOS Startbildschirm"></img>
            <div className="user-guide-image-description">iOS Startbildschirm</div>
            <p>Im erschienenen Menü wähle die eingekreiste "Zum Homescreen" option.</p>
            <img className="user-guide-image-mobile" src={iOSContextMenu} alt="iOS Optionen"></img>
            <div className="user-guide-image-description">iOS Optionen</div>
            <p>Nun wähle "Hinzufügen" oben rechts und die App erscheint auf dem Home Bildschirm.</p>
            <img className="user-guide-image-mobile" src={iOSHomescreen} alt="iOS Homescreen"></img>
            <div className="user-guide-image-description">iOS zu Homescreen hinzufügen</div>
        </div>;
    }

}

export default UserGuideInstallation;