import React, { Component } from 'react';
import './PlayerCell.css'
import Translator from '../../../scripts/Translator';

class PlayerCell extends Component {

    onClick(e) {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    }

    render() {
        var licensedLabel = Translator.get("licensed");;
        if (this.props.member.license_number.length === 0) {
            licensedLabel = Translator.get("not_licensed");
        }
        var splitted = this.props.member.birthdate.split("-");
        var birthdateLabel = splitted[2]+"."+splitted[1]+"."+splitted[0];
        return (
            <div className="player-cell" onClick={this.onClick.bind(this)}>
                <div className="player-cell-line">
                    <span className="player-cell-first-name">{this.props.member.first_name}</span>
                    <span className="player-cell-last-name">{this.props.member.last_name}</span>
                    <span className="player-cell-birthdate">{birthdateLabel}</span>
                </div>
                <div className="player-cell-line">
                    <span className="player-cell-email">{this.props.member.email}</span>
                    <span className="player-cell-license">{licensedLabel}</span>
                </div>
            </div>
        );
    }
}

export default PlayerCell;