import React, { Component } from 'react';
import './Login.css';
import Popup from '../../components/popup/Popup.js';

import volleyLogo from '../../images/volley-logo.png'
import ActivityIndicator from '../../components/activity-indicator/ActivityIndicator';
import Translator from '../scripts/Translator';
import Api from '../scripts/Api';
import Checkbox from '../../components/checkbox/Checkbox';
import Input from '../../components/input/Input';


class Login extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            state: "waiting",
            username: "",
            password: "",
            email: "",
            firstname: "",
            lastname: "",
            birthdate: "",
            wantCookie: false
        };
    }

    componentDidMount() {
        let _this = this;
        Api.post("/login", {username: "", password: "", want_cookie: false}, response => {
            _this.props.onAuthentication(response);
        }, e => {
            if (e.error !== "invalid_cookie") {
                _this.setState({loading: false, error: e.error});
            }
        });
    }

    startLogin() {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.post("/login", {username: this.state.username, password: this.state.password, want_cookie: this.state.wantCookie}, response => {
            _this.setState({loading: false});            
            _this.props.onAuthentication(response);
        }, e => {
            _this.setState({loading: false, error: e.error});
        });
    }

    startSignup() {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.post("/signup", {
            username: this.state.username, 
            password: this.state.password,
            email: this.state.email,
            first_name: this.state.firstname,
            last_name: this.state.lastname,
            birthdate: this.state.birthdate
        }, () => {
            _this.setState({loading: false, error: Translator.get("signup_success_message"), view: "login"}); // misuse error for sucess popup
        }, e => {
            _this.setState({loading: false, error: e.error});
        });
    }

    showHelp() {
        this.props.showHelp();
    }

    showSignup() {
        this.setState({view: "signup"});
    }

    onValueChange(id, e) {
        let s = this.state;
        s[id] = e.target.value;
        this.setState(s);
    }

    changeCheckbox() {
        this.setState({wantCookie: !this.state.wantCookie});
    }

    render() {
        var loginTitle = Translator.get("login_title");
        var loginButton = Translator.get("login_button");
        var passwordPlaceholder = Translator.get("login_password_placeholder");
        var usernamePlaceholder = Translator.get("login_username_placeholder");
        var signupTitle = Translator.get("signup_title");
        if (loginTitle === "login_title") {
            // Fallback if translator is not yet ready.
            loginTitle = "Login"; 
            loginButton = "Anmelden";
            passwordPlaceholder = "Passwort";
            usernamePlaceholder = "Benutzername";
        }
        if (this.state.view === "signup") {
            return (
                <div className="login-screen">
                    {this.state.error && <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>}
                    {this.state.loading && <Popup><ActivityIndicator></ActivityIndicator></Popup>}
                    <h1 className="login-title">{signupTitle}</h1>
                    <div className="login-space"></div>
                    <Input appearance="fill" className="login-input" label={usernamePlaceholder} 
                        value={this.state.username} onChange={this.onValueChange.bind(this, "username")}>
                    </Input>
                    <div className="login-space-tiny"></div>
                    <Input appearance="fill" className="login-input" type="password" label={passwordPlaceholder} 
                        value={this.state.password} onChange={this.onValueChange.bind(this, "password")}>
                    </Input>
                    <div className="login-space-tiny"></div>
                    <Input appearance="fill" className="login-input" type="email" label={Translator.get("signup_email_placeholder")} 
                        value={this.state.email} onChange={this.onValueChange.bind(this, "email")}>
                    </Input>
                    <div className="login-space-tiny"></div>
                    <Input appearance="fill" className="login-input" label={Translator.get("signup_firstname_placeholder")} 
                        value={this.state.firstname} onChange={this.onValueChange.bind(this, "firstname")}>
                    </Input>
                    <div className="login-space-tiny"></div>
                    <Input appearance="fill" className="login-input" label={Translator.get("signup_lastname_placeholder")} 
                        value={this.state.lastname} onChange={this.onValueChange.bind(this, "lastname")}>
                    </Input>
                    <div className="login-space-tiny"></div>
                    <Input appearance="fill" className="login-input" type="date" label={Translator.get("signup_birthdate_placeholder")} 
                        value={this.state.birthdate} onChange={this.onValueChange.bind(this, "birthdate")}>
                    </Input>
                    <div className="login-space"></div>
                    <div className="login-button" onClick={this.startSignup.bind(this)}>{Translator.get("signup_button")}</div>
                    <div className="login-space"></div>
                </div>
            );
        }
        return (
            <div className="login-screen">
                {this.state.error && <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>}
                {this.state.loading && <Popup><ActivityIndicator></ActivityIndicator></Popup>}
                <h1 className="login-title">{loginTitle}</h1>
                <div className="login-space"></div>
                <div className="login-space"></div>
                <Input appearance="fill" label={usernamePlaceholder} className="login-input"
                    value={this.state.username} onChange={this.onValueChange.bind(this, "username")}>
                </Input>
                <div className="login-space"></div>
                <Input appearance="fill" className="login-input" type="password" label={passwordPlaceholder} 
                    value={this.state.password} onChange={this.onValueChange.bind(this, "password")}>
                </Input>
                <div className="login-space"></div>
                <div className="login-cookie-checkbox-wrapper">
                    <Checkbox className="login-cookie-checkbox" checked={this.state.wantCookie} onCheck={this.changeCheckbox.bind(this)}></Checkbox>
                    <span>{Translator.get("cookie-checkbox-label")}</span>
                </div>
                <div className="login-space"></div>
                <div className="login-button" onClick={this.startLogin.bind(this)}>{loginButton}</div>                
                <div className="login-space-tiny"></div>
                <div className="login-help-button" onClick={this.showSignup.bind(this)}>{Translator.get("signup_button")}</div>
                <div className="login-space-tiny"></div>
                <div className="login-help-button" onClick={this.showHelp.bind(this)}>{Translator.get("help")}</div>
                <div className="login-space"></div>
                <div className="login-logo-wrapper">
                    <img className="login-logo" src={volleyLogo} alt="Volley Hünenberg Logo"></img>
                </div>
                <span className="login-version">{this.props.version}</span>
            </div>
        );
    }
}

export default Login;