import React, { Component } from 'react';
import './GamesController.css'
import NavigationBar from '../../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../../scripts/Translator';
import BackIcon from '../../../../../images/back.png';
import Api from '../../../../scripts/Api';
import Popup from '../../../../../components/popup/Popup';
import ActivityIndicator from '../../../../../components/activity-indicator/ActivityIndicator';

class GamesController extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    onUpdateClick() {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.post("/swiss-volley/games", {}, () => {
            _this.setState({loading: false});
            _this.props.onBack();
        }, e => {
            _this.setState({loading: false, error: e.error});
        });
    }

    render() {
        return (
            <div className="excel">
                <NavigationBar title={Translator.get("game_data_title")} buttonsLeft={
                    <img src={BackIcon} alt="Back" onClick={this.props.onBack}></img>
                }/>
                {this.state.loading && <Popup><ActivityIndicator></ActivityIndicator></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="games-content">
                    <div className="games-button" onClick={this.onUpdateClick.bind(this)}>{Translator.get("update_game_data_button")}</div>
                </div>
            </div>
        );
    }

}

export default GamesController;