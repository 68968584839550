import React, { Component } from 'react';
import './UserGuideStyles.css'
import StartPage from '../../images/ug-start-page.png'
import LoginScreen from '../../images/ug-login-screen.png'
import WaitVerify from '../../images/ug-wait-verify.png'
import VerificationEmail from '../../images/ug-verification-email.png'
import VerificationSuccess from '../../images/ug-verify-success.png'

class UserGuideRegistration extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <div>
            <h1 className="user-guide-title">Registrierung</h1>
            <p className="user-guide-warning">Nur Mitglieder von Volley Hünenberg können sich registrieren. Ausserdem müssen die angegebenen Daten genau mit den bereits vom 
            Verein erfassten Daten übereinstimmen. Falls bei der Registrierung ein Problem auftritt melde dich bei deinem Trainer oder einem Vorstandsmitglied.</p>
            <h2 className="user-guide-section">Schritt 1: Webapp öffnen</h2>
            <p>Öffne die Webapp und klicke auf den "Konto erstellen" Link, welcher sich gleich unter dem "Anmelden" Knopf befindet.</p>
            <img className="user-guide-image-mobile" src={StartPage} alt="App Startbildschirm"></img>
            <div className="user-guide-image-description">Startbildschirm der Webapp</div>

            <h2 className="user-guide-section">Schritt 2: Konto erstellen</h2>
            <p>Wähle einen Benutzernamen und ein starkes Passwort und fülle die weiteren Felder aus. Danach klicke auf "Konto erstellen".</p>
            <img className="user-guide-image-mobile" src={LoginScreen} alt="Registrierungsformular"></img>
            <div className="user-guide-image-description">Registrierungsformular</div>

            <h2 className="user-guide-section">Schritt 3: Email Adresse bestätigen</h2>
            <p>Du hast in der Zwischenzeit eine Email erhalten, welche einen Link zur Bestätigung der Adresse beinhaltet.</p>
            <img className="user-guide-image" src={VerificationEmail} alt="Bestätigungsemail"></img>
            <div className="user-guide-image-description">Bestätigungsemail</div>
            <p>Klicke auf den Link und dein Konto wird aktiviert.</p>
            <img className="user-guide-image-mobile" src={VerificationSuccess} alt="Konto Aktivierung"></img>
            <div className="user-guide-image-description">Konto Aktivierung</div>
            <p>Nun kannst du dich mit deinem gewählten Benutzernamen und Passwort Anmelden.</p>
            <img className="user-guide-image-mobile" src={WaitVerify} alt="Login Bildschirm"></img>
            <div className="user-guide-image-description">Login Bildschirm</div>
        </div>;
    }

}

export default UserGuideRegistration;