import React, { Component } from 'react';
import './Protocol.css';
import NavigationBar from '../../../components/navigation-bar/NavigationBar.js'
import Translator from '../../scripts/Translator';
import Api from '../../scripts/Api';

class Protocol extends Component {

    componentDidMount() {
        this.setState({loading: true});
        var _this = this;
        Api.get("/protocol", body => {
            _this.protocolEntries = body.map((entry, i) => 
                _this.createEntry(i, entry.action, entry.params, entry.user_name, entry.timestamp)
            );
            _this.setState({loading: false});
        }, error => {
            _this.setState({error: error.error, loading: false});
        }, 60*10);
    }

    createEntry(index, action, params, user, timestamp) {
        var text;
        timestamp = new Date(timestamp);
        timestamp = timestamp.getDate() + "." + this.dateFormat(timestamp.getMonth()+1) + "." + timestamp.getFullYear() + " " + this.dateFormat(timestamp.getHours())
             + ":" + this.dateFormat(timestamp.getMinutes());
        var weekdays = [Translator.get("monday"), Translator.get("tuesday"), Translator.get("wednsday"), Translator.get("thursday"), Translator.get("friday"), 
            Translator.get("saturday"), Translator.get("sunday")];
        if (action === "add_role") {
            text = Translator.get("add_role_1") + params[0] + Translator.get("add_role_2");
        }
        else if (action === "update_role") {
            text = Translator.get("update_role_1") + params[0] + Translator.get("update_role_2");
        }
        else if (action === "add_user") {
            text = Translator.get("add_user_1") + params[0] + Translator.get("add_user_2");
        }
        else if (action === "update_user") {
            text = Translator.get("update_user_1") + params[0] + Translator.get("update_user_2");
        }
        else if (action === "add_team") {
            text = Translator.get("add_team_1") + params[0] + Translator.get("add_team_2");
        }
        else if (action === "delete_team") {
            text = Translator.get("delete_team_1") + params[0] + Translator.get("delete_team_2");
        }
        else if (action === "update_team") {
            text = Translator.get("update_team_1") + params[0] + Translator.get("update_team_2");
        }
        else if (action === "add_member") {
            text = Translator.get("add_member_1") + params[0] + Translator.get("add_member_2");
        }
        else if (action === "update_member") {
            text = Translator.get("update_member_1") + params[0] + Translator.get("update_member_2");
        }
        else if (action === "add_member_team") {
            text = params[0] + Translator.get("add_member_team_1") + params[1] + Translator.get("add_member_team_2");
        }
        else if (action === "remove_member_team") {
            text = params[0] + Translator.get("remove_member_team_1") + params[1] + Translator.get("remove_member_team_2");
        }
        else if (action === "add_coach_team") {
            text = Translator.get("add_coach_team_1") + params[0] + Translator.get("add_coach_team_2") + params[1] + Translator.get("add_coach_team_3");
        }
        else if (action === "remove_coach_team") {
            text = Translator.get("remove_coach_team_1") + params[0] + Translator.get("remove_coach_team_2") + params[1] + Translator.get("remove_coach_team_3");
        }
        else if (action === "add_location") {
            text = Translator.get("add_location_1") + params[0] + Translator.get("add_location_2");
        }
        else if (action === "update_location") {
            text = Translator.get("update_location_1") + params[0] + Translator.get("update_location_2");
        }
        else if (action === "remove_location") {
            text = Translator.get("remove_location_1") + params[0] + Translator.get("remove_location_2");
        }
        else if (action === "add_training") {
            text = Translator.get("add_training_1") + weekdays[params[0]] + ", " + params[1] + "-" + params[2] + Translator.get("add_training_2") +
                params[3] + Translator.get("add_training_3");
        }
        else if (action === "update_training") {
            text = Translator.get("update_training_1") + weekdays[params[0]] + ", " + params[1] + "-" + params[2] + Translator.get("update_training_2") +
                params[3] + Translator.get("update_training_3");
        }
        else if (action === "remove_training") {
            text = Translator.get("remove_training_1") + weekdays[params[0]] + ", " + params[1] + "-" + params[2] + Translator.get("remove_training_2") +
                params[3] + Translator.get("remove_training_3");
        }
        else if (action === "add_board_member") {
            text = Translator.get("add_board_member_1") + params[0] + " (" + params[1] + ") " + Translator.get("add_board_member_2");
        }
        else if (action === "update_board_member") {
            text = Translator.get("update_board_member_1") + params[0] + " (" + params[1] + ") " + Translator.get("update_board_member_2");
        }
        else if (action === "remove_board_member") {
            text = Translator.get("remove_board_member_1") + params[0] + " (" + params[1] + ") " + Translator.get("remove_board_member_2");
        }
        else if (action === "add_file") {
            text = Translator.get("add_file_1") + params[0] + Translator.get("add_file_2");
        }
        else if (action === "update_file") {
            text = Translator.get("update_file_1") + params[0] + Translator.get("update_file_2");
        }
        else if (action === "remove_file") {
            text = Translator.get("remove_file_1") + params[0] + Translator.get("remove_file_2");
        }
        else if (action === "add_sponsor") {
            text = Translator.get("add_sponsor_1") + params[0] + Translator.get("add_sponsor_2");
        }
        else if (action === "update_sponsor") {
            text = Translator.get("update_sponsor_1") + params[0] + Translator.get("update_sponsor_2");
        }
        else if (action === "remove_sponsor") {
            text = Translator.get("remove_sponsor_1") + params[0] + Translator.get("remove_sponsor_2");
        }
        else if (action === "add_event") {
            text = Translator.get("add_event_1") + params[0] + Translator.get("add_event_2");
        }
        else if (action === "update_event") {
            text = Translator.get("update_event_1") + params[0] + Translator.get("update_event_2");
        }
        else if (action === "remove_event") {
            text = Translator.get("remove_event_1") + params[0] + Translator.get("remove_event_2");
        }
        else if (action === "add_member_request") {
            text = params[0] + Translator.get("add_member_request_1");
        }
        else {
            console.log("Unknown protocol event " + action);
        }
        return (
            <div key={index} className="protocol-entry">
                <div className="protocol-entry-text">{text}</div>
                <div className="protocol-entry-detail">
                    <span className="protocol-entry-detail-user">{user}</span>
                    <span className="protocol-entry-detail-timestamp">{timestamp}</span>
                </div>
            </div>
        );
    }

    dateFormat(date) {
        date = ""+date;
        if (date.length === 1) {
            return "0"+date;
        }
        return date;
    }

    render() {
        return (
            <div className="protocol">
                <NavigationBar className="protocol-navigation-bar" title={Translator.get("protocol_title")}/>
                <div className="protocol-content">
                    {this.protocolEntries}
                </div>
            </div>
            
        );
    }
}

export default Protocol;