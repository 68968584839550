import React, { Component } from 'react';
import './EventEdit.css'
import NavigationBar from '../../../components/navigation-bar/NavigationBar';
import Translator from '../../scripts/Translator';
import Api from '../../scripts/Api';
import Popup from '../../../components/popup/Popup';
import ActivityIndicator from '../../../components/activity-indicator/ActivityIndicator';
import Checkbox from '../../../components/checkbox/Checkbox';
import Input from '../../../components/input/Input';
import Dropdown from '../../../components/dropdown/Dropdown';
import Textarea from '../../../components/textarea/Textarea';

class EventEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.event.title,
            location: props.event.location,
            event_address: props.event.address,
            start_time: props.event.start_time,
            end_time: props.event.end_time,
            deadline_days: Math.floor(Number(props.event.deadline)/24),
            deadline_hours: Number(props.event.deadline)%24,
            description: props.event.description,
            date: props.event.date,
            invalids: {}
        };
    }

    componentDidMount() {
        this.setState({loading: true});
        Api.get("/teams", res => {
            this.teams = res;
            var teamsChecked = {};
            for (let i = 0; i < res.length; i++) {
                teamsChecked[res[i].id] = false;
            }
            Api.get("/events/"+this.props.event.id+"/teams", res => {
                for (let i = 0; i < res.length; i++) {
                    teamsChecked[res[i].id] = true;
                }
                this.setState({loading: false, selectedTeams: teamsChecked});
            }, e => {
                this.setState({loading: false, error: e.error});
            }, 600);
        }, e => {
            this.setState({loading: false, error: e.error});
        }, 600);
    }

    onValueChanged(key, e) {
        var s = {};
        s[key] = e.target.value;
        this.setState(s);
    }

    onTeamCheck(id) {
        var s = this.state.selectedTeams;
        s[id] = !s[id];
        this.setState({selectedTeams: s});
    }

    onCancel() {
        this.props.onBack();
    }

    onSave() {
        var invalids = {
            title: false,
            location: false,
            start_time: false,
            end_time: false
        };
        var hasError = false;
        if (this.state.title === "") {
            invalids.title = true;
            hasError = true;
        }
        if (this.state.location === "") {
            invalids.location = true;
            hasError = true;
        }
        if (this.state.start_time === "") {
            invalids.start_time = true;
            hasError = true;
        }
        if (this.state.end_time === "") {
            invalids.end_time = true;
            hasError = true;
        }
        if (hasError) {
            this.setState({invalids: invalids});
            return;
        }
        var teamIds = [];
        for (var id in this.state.selectedTeams) {
            if (this.state.selectedTeams[id]) {
                teamIds.push(id);
            }
        }
        var event = {
            title: this.state.title,
            location: this.state.location,
            address: this.state.event_address,
            date: this.state.date,
            start_time: this.state.start_time,
            end_time: this.state.end_time,
            deadline: Number(this.state.deadline_days)*24 + Number(this.state.deadline_hours),
            description: this.state.description,
            team_ids: teamIds
        };
        this.setState({loading: true});
        Api.put("/events/"+this.props.event.id, event, () => {
            this.setState({loading: false});
            this.props.onEventEdited();
        }, e => {
            this.setState({error: e.error, loading: false});
        });
    }

    onDelete() {
        this.setState({loading: true});
        Api.delete("/events/"+this.props.event.id, {}, () => {
            this.setState({loading: false});
            this.props.onEventEdited();
        }, e => {
            this.setState({error: e.error, loading: false});
        });
    }

    render() {
        var dayItems = [<option key={0} value={0}>{"0 " + Translator.get("days")}</option>, <option key={1} value={1}>{"1 " + Translator.get("day")}</option>];
        for (var i = 2; i <= 30; i++) {
            dayItems.push(<option key={i} value={i}>{i + " " + Translator.get("days")}</option>);
        }
        var hourItems = [<option key={0} value={0}>{"0 " + Translator.get("hours")}</option>, <option key={1} value={1}>{"1 " + Translator.get("hour")}</option>];
        for (i = 2; i <= 30; i++) {
            hourItems.push(<option key={i} value={i}>{i + " " + Translator.get("hours")}</option>);
        }
        return <div className="event-edit">
            <NavigationBar title={Translator.get("new_event")} buttonsLeft={
                <div onClick={this.onCancel.bind(this)}>{Translator.get("cancel")}</div>
            }buttonsRight={
                <div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>
            }/>
            <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
            {this.state.loading && <Popup><ActivityIndicator/></Popup>}
            <div className="event-edit-content">
                <Input type="text" className="event-edit-input"
                    value={this.state.title} 
                    onChange={this.onValueChanged.bind(this, "title")}
                    label={Translator.get("title")}
                    error={this.state.invalids.title ? "" : null}>
                </Input>
                <Input type="text" className="event-edit-input"
                    value={this.state.location} 
                    onChange={this.onValueChanged.bind(this, "location")}
                    label={Translator.get("location")}
                    error={this.state.invalids.location ? "" : null}>
                </Input>
                <Input type="text" className="event-edit-input" 
                    value={this.state.event_address} 
                    onChange={this.onValueChanged.bind(this, "event_address")}
                    label={Translator.get("event_address")}>
                </Input>
                <Input type="time" className="event-edit-input"
                    value={this.state.start_time} 
                    onChange={this.onValueChanged.bind(this, "start_time")}
                    label={Translator.get("start_time")}
                    error={this.state.invalids.start_time ? "" : null}>
                </Input>
                <Input type="time" className="event-edit-input"
                    value={this.state.end_time} 
                    onChange={this.onValueChanged.bind(this, "end_time")}
                    label={Translator.get("end_time")}
                    error={this.state.invalids.end_time ? "" : null}>
                </Input>
                <div className="event-edit-deadline">
                    <Dropdown className="event-edit-input" value={this.state.deadline_days} 
                        onChange={this.onValueChanged.bind(this, "deadline_days")}
                        label={Translator.get("event_deadline")}>
                        {dayItems}
                    </Dropdown>
                    <Dropdown className="event-edit-input" value={this.state.deadline_hours} 
                        onChange={this.onValueChanged.bind(this, "deadline_hours")}>
                        {hourItems}
                    </Dropdown>
                    <span>{Translator.get("event_deadline_text")}</span>
                </div>
                <Textarea className="event-edit-input" value={this.state.description} 
                    onChange={this.onValueChanged.bind(this, "description")} 
                    rows="2"
                    label={Translator.get("event_description")}>
                </Textarea>
                <h3 className="event-new-teams-title">{Translator.get("event_teams_title")}</h3>
                {this.teams && this.teams.map((t, i) => 
                    <div className="event-edit-team-row" key={i}>
                        <Checkbox checked={this.state.selectedTeams && this.state.selectedTeams[t.id]} onCheck={this.onTeamCheck.bind(this, t.id)}></Checkbox>
                        <div className="event-edit-team-row-name">{t.name}</div>                
                    </div>)
                }
                <div onClick={this.onDelete.bind(this)} className="event-edit-remove">{Translator.get("delete")}</div>
            </div>
        </div>;
    }

}

export default EventEdit;