import React, { Component } from 'react';
import './RolesController.css';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar.js';
import ListViewItem from '../../../../components/list-view/ListViewItem.js';
import BackIcon from '../../../../images/back.png';
import AddIcon from '../../../../images/add.png';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import Popup from '../../../../components/popup/Popup';
import Api from '../../../scripts/Api';
import RoleDetail from './RoleDetail';
import Translator from '../../../scripts/Translator';

class RolesController extends Component {

    constructor(props) {
        super(props);
        this.state = {loading: false, view: "menu"};
    }

    componentDidMount() {
        this.loadRoles(60*10)
    }

    onBackClick() {
        // request exit from parent
        this.props.onBack();
    }

    onBack() {
        // child requests exit
        this.setState({view: "menu"});
    }

    onRoleAdd() {
        this.loadRoles(0);
        this.setState({view: "menu"});
    }

    loadRoles(cache) {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.get("/roles", body => {
            _this.accessControlExample = body.access_control;
            _this.roleElements = body.roles.map((role, i) => <ListViewItem key={i} text={role.name} disclosureIndicator onClick={this.onRoleClick.bind(this, role)}/>);
            _this.setState({loading: false});
        }, error => {
            _this.setState({error: error.error, loading: false});
        }, cache);
    }

    onAddClick() {
        this.selectedRole = {name: Translator.get("new_role"), access_control: this.accessControlExample};
        this.setState({view: "role-detail"});
    }

    onRoleClick(role) {
        this.selectedRole = role;
        this.setState({view: "role-detail"});
    }

    render() {
        if (this.state.view === "role-detail") {
            return <RoleDetail role={this.selectedRole} onBack={this.onBack.bind(this)} onRoleAdd={this.onRoleAdd.bind(this)}/>;
        }
        var content;
        if (this.state.loading) {
            content =  <ActivityIndicator className="roles-controller-spinner"/>;
        }
        else {
            content = (
                <div className="roles-controller-list-view">
                    {this.roleElements}
                </div>
            );
        }
        return (
            <div>
                <NavigationBar title={Translator.get("roles_title")} buttonsLeft={
                    <img src={BackIcon} alt="Back" onClick={this.onBackClick.bind(this)}></img>
                } buttonsRight={
                    <img src={AddIcon} alt="Add Role" onClick={this.onAddClick.bind(this)}></img>
                }/>
                {content}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
            </div>
        );
    }
}

export default RolesController;