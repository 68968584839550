import React, { Component } from 'react';
import NavigationBar from '../../../components/navigation-bar/NavigationBar.js'
import TeamDetail from './TeamDetail.js'
import './TeamsController.css'

import AddIcon from '../../../images/add.png'
import Translator from '../../scripts/Translator.js';
import Api from '../../scripts/Api.js';
import Popup from '../../../components/popup/Popup.js';
import ActivityIndicator from '../../../components/activity-indicator/ActivityIndicator.js';
import ListViewItem from '../../../components/list-view/ListViewItem.js';
import MemberRequestController from './member-requests/MemberRequestController.js';

class TeamsController extends Component {

    constructor(props) {
        super(props);
        this.state = {view: "overview"};
    }

    componentDidMount() {
        this.loadTeams(60*10)
        this.loadMemberRequests(60*10);
    }

    loadTeams(cache) {
        this.selectedTeam = null;
        this.setState({loading: true});
        var _this = this;
        Api.get("/teams", body => {
            _this.teamElements = body.map((team, i) => 
                <ListViewItem key={i} text={team.name} disclosureIndicator onClick={this.onTeamClick.bind(this, team)}/>);
            _this.setState({loading: false});
        }, error => {
            _this.setState({error: error.error, loading: false});
        }, cache);        
    }

    loadMemberRequests(cache) {
        let _this = this;
        if (this.props.context.user.role.access_control.view_member_requests) {
            Api.get("/member-requests", body => {
                _this.setState({memberRequests: body});
            }, error => {
                _this.setState({error: error.error, loading: false});
            }, cache);
        }
    }

    onMemberRequestClick() {
        this.setState({view: "member-requests"});
    }

    onTeamClick(team) {
        this.selectedTeam = team;
        this.setState({view: "detail"})
    }

    onAddClick() {
        this.selectedTeam = null;
        this.setState({view: "detail"});
    }

    onTeamAdd() {
        this.loadTeams(0);
        this.setState({view: "overview"});
    }

    onMemberRequestChanged() {
        this.loadMemberRequests(0);
        this.setState({view: "overview"});
    }

    onChildBack() {
        this.loadTeams(10);
        this.setState({view: "overview"});
    }

    render() {
        if (this.state.view === "member-requests") {
            return <MemberRequestController requests={this.state.memberRequests} onBack={this.onChildBack.bind(this)} onMemberRequestChanged={this.onMemberRequestChanged.bind(this)}></MemberRequestController>
        }
        if (this.state.view === "detail") {
            return <TeamDetail context={this.props.context} onBack={this.onChildBack.bind(this)} team={this.selectedTeam} onTeamAdd={this.onTeamAdd.bind(this)}/>;
        }
        var content;
        if (this.state.error) {
            content = <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>;
        }
        else if (this.state.loading) {
            content = <ActivityIndicator className="roles-controller-spinner"/>;
        }
        else {
            content = (
                <div className="teams-controller-list-view">
                    {this.state.memberRequests && this.state.memberRequests.length>0 && 
                        <ListViewItem key={-1} text="Mitglieder Anfragen" badgeNumber={this.state.memberRequests.length} disclosureIndicator onClick={this.onMemberRequestClick.bind(this)}/>}
                    {this.teamElements}
                </div>
            );
        }
        return (
            <div>
                <NavigationBar title={Translator.get("teams_title")} buttonsRight={
                    this.props.canAdd && <img src={AddIcon} alt="Add Team Button" onClick={this.onAddClick.bind(this)}></img>
                }/>                
                {content}
            </div>
        );
    }
}

export default TeamsController;