import React, { Component } from 'react';
import './UserGuideStyles.css'
import TeamDetail from '../../images/ug-team-detail.png'
import CoachOverview from '../../images/ug-coach-overview.png'
import CoachSearch from '../../images/ug-coach-search.png'
import PlayerDetail from '../../images/ug-player-detail.png'

class UserGuideAddCoach extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <div>
            <h1 className="user-guide-title">Trainer hinzufügen</h1>
            <p className="user-guide-warning">Du kannst nur denjenigen Teams Trainer hinzufügen bei welchen du bereits als Trainer eingetragen bist.</p>
            <h2 className="user-guide-section">Schritt 1: Team auswählen</h2>
            <p>Öffne die Webapp und klicke unten auf den "Teams" Tab. Wähle das Team welchem du einen Trainer hinzufügen möchtest. Wähle dann "Trainer bearbeiten".</p>
            <img className="user-guide-image-mobile" src={TeamDetail} alt="Team Detailansicht"></img>
            <div className="user-guide-image-description">Team Detailansicht</div>

            <h2 className="user-guide-section">Schritt 2: Trainer suchen</h2>
            <p>Klicke oben rechts auf das Plus.</p>
            <img className="user-guide-image-mobile" src={CoachOverview} alt="Trainerübersicht"></img>
            <div className="user-guide-image-description">Trainerübersicht</div>
            <p>Suche nach dem Namen des Trainers in der Datenbank. Dadurch sollen duplikate vermieden werden. Falls der gewünschte Trainer gefunden wird klicke auf ihn, ansonsten
                wähle "Neuer Trainer". </p>
            <img className="user-guide-image-mobile" src={CoachSearch} alt="Trainersuche"></img>
            <div className="user-guide-image-description">Trainersuche</div>

            <h2 className="user-guide-section">Schritt 3: Trainer erfassen</h2>
            <p>Überprüfe und ergänze die Daten. Danach wähle "Hinzufügen" oben rechts.</p>
            <img className="user-guide-image-mobile" src={PlayerDetail} alt="Ansicht der Spielerdaten"></img>
            <div className="user-guide-image-description">Ansicht der Spielerdaten</div>
        </div>;
    }

}

export default UserGuideAddCoach;