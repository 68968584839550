import React, { Component } from 'react';
import './MemberRequestAccept.css'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar.js'
import Translator from '../../../scripts/Translator';
import BackIcon from '../../../../images/back.png';
import Checkbox from '../../../../components/checkbox/Checkbox';
import Api from '../../../scripts/Api';
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';

class MemberRequestAccept extends Component {

    constructor() {
        super();
        this.state = {
            loading: false,
            teams: [],
            selected: {}
        };
    }

    componentDidMount() {
        this.loadTeams();
    }

    onSave() {
        let _this = this;
        Api.post("/members", this.props.member, res => {
            for (let key in this.state.selected) {
                if (this.state.selected[key] === true) {
                    Api.post("/teams/"+key+"/players", {id: res.id}, () => {
                    }, e => {
                        _this.setState({loading: false, error: e.error});
                    });
                }
            }
            Api.delete("/member-requests/"+this.props.member.id, {}, () => {
                _this.props.onMemberRequestChanged();
            }, e => {
                _this.setState({error: e.error, loading: false});
            });
        }, e => {
            _this.setState({loading:false, error: e.error});
        });
    }

    loadTeams() {
        this.setState({loading: true});
        var _this = this;
        Api.get("/teams", body => {
            _this.setState({teams: body});
            _this.setState({loading: false});
        }, error => {
            _this.setState({error: error.error, loading: false});
        }, 60*10);
    }

    selectTeam(id) {
        let s = this.state.selected;
        if (s[id]) {
            s[id] = false;
        }
        else {
            s[id] = true;
        }
        this.setState({selected: s});
    }

    onBack() {
        this.props.onBack();
    }

    render() {
        return (
            <div className="member-request-accept-controller">
                <NavigationBar title={Translator.get("teams_title")} 
                    buttonsLeft={<img src={BackIcon} alt="Back" onClick={this.onBack.bind(this)}></img>}
                    buttonsRight={<div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>}/>
                <div className="member-request-accept-controller-content">
                    {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                    <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                    <div className="member-request-accept-controller-text">{this.props.member.first_name} {this.props.member.last_name} {Translator.get("request_accept_text")}</div>
                    {this.state.teams.map((team, i) => 
                        <div key={i} className="member-request-accept-team-item">
                            <Checkbox onCheck={this.selectTeam.bind(this, team.id)} checked={this.state.selected[team.id]}></Checkbox>
                            <div className="member-request-accept-team-item-name">{team.name}</div>
                        </div>)
                    }
                </div>
            </div>
            
        );
    }
}

export default MemberRequestAccept;