import React, { Component } from 'react';
import './UserGuideStyles.css'
import TeamDetail from '../../images/ug-team-detail.png'
import TrainingDetail from '../../images/ug-training-detail.png'

class UserGuideAddTraining extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <div>
            <h1 className="user-guide-title">Training hinzufügen</h1>
            <p className="user-guide-warning">Du kannst nur denjenigen Teams Trainings hinzufügen bei welchen du als Trainer eingetragen bist.</p>
            <h2 className="user-guide-section">Schritt 1: Team auswählen</h2>
            <p>Öffne die Webapp und klicke unten auf den "Teams" Tab. Wähle das Team welchem du einen Trainer hinzufügen möchtest. Wähle dann "Trainings bearbeiten".</p>
            <img className="user-guide-image-mobile" src={TeamDetail} alt="Team Detailansicht"></img>
            <div className="user-guide-image-description">Team Detailansicht</div>

            <h2 className="user-guide-section">Schritt 2: Training erfassen</h2>
            <p>Klicke das Plus oben rechts und trage die Informationen ein. Drücke dann auf "Speichern".</p>
            <img className="user-guide-image-mobile" src={TrainingDetail} alt="Trainingsansicht"></img>
            <div className="user-guide-image-description">Trainingsansicht</div>
        </div>;
    }

}

export default UserGuideAddTraining;