import React, { Component } from 'react';
import './ExcelController.css'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../scripts/Translator';
import BackIcon from '../../../../images/back.png';
import Api from '../../../scripts/Api';

class ExcelController extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="excel">
                <NavigationBar title={Translator.get("excel_title")} buttonsLeft={
                    <img src={BackIcon} alt="Back" onClick={this.props.onBack}></img>
                }/>
                <div className="excel-content">
                    <a className="excel-button" href={Api.getExcelURL()} target="_blank" rel="noopener noreferrer">{Translator.get("create_excel_button")}</a>
                </div>
            </div>
        );
    }

}

export default ExcelController;