import React, { Component } from 'react';
import './ProfileEdit.css'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../scripts/Translator';
import Api from '../../../scripts/Api';
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import Input from '../../../../components/input/Input';

class ProfileEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            profile: props.profile,
            invalids: {}
        };
    }

    onCancel() {
        this.props.onBack();
    }

    onSave() {
        var didError = false;
        var invalids = {
            first_name: false,
            last_name: false,
            email: false,
            phone_number: false,
            birthdate: false,
            address: false,
            zip_code: false,
            locality: false
        };
        if (this.state.profile.first_name.length === 0) {
            didError = true;
            invalids.first_name = true;
        }
        if (this.state.profile.last_name.length === 0) {
            didError = true;
            invalids.last_name = true;
        }
        if (this.state.profile.address.length === 0) {
            didError = true;
            invalids.address = true;
        }
        if (this.state.profile.zip_code.length === 0) {
            didError = true;
            invalids.zip_code = true;
        }
        if (this.state.profile.locality.length === 0) {
            didError = true;
            invalids.locality = true;
        }
        if (this.state.profile.birthdate.length === 0) {
            didError = true;
            invalids.birthdate = true;
        }
        var emailSplitted = this.state.profile.email.split("@");
        if (emailSplitted.length !== 2) {
            didError = true;
            invalids.email = true;
        }
        else {
            emailSplitted = emailSplitted[1].split(".");
            if (emailSplitted.length === 1) {
                didError = true;
                invalids.email = true;
            }
        }
        var phoneNumber = this.state.profile.phone_number;
        if (phoneNumber.length !== 13) { // 000_000_00_00
            if (!phoneNumber) {
                didError = true;
                invalids.phone_number = true;
            }
            else {
                phoneNumber = this.state.profile.phone_number.slice(0,3) + " " + this.state.profile.phone_number.slice(3,6) + " " + this.state.profile.phone_number.slice(6,8)
                    + " " + this.state.profile.phone_number.slice(8,10);
                if (phoneNumber.length !== 13) {
                    didError = true;
                    invalids.phone_number = true;
                }
                let s = this.state.profile;
                s["phone_number"] = phoneNumber;
                this.setState({profile: s});
            }            
        }
        if (didError) {
            this.setState({invalids: invalids});
            return;
        }
        this.setState({loading: true, error: null});
        var _this = this;
        Api.put("/members/"+this.state.profile.id, this.state.profile, () => {
            _this.props.onSaved()
        }, e => {
            _this.setState({error: e.error, loading: false});
        });
    }

    onValueChange(id, e) {
        let s = this.state.profile;
        s[id] = e.target.value;
        this.setState({profile: s});
    }

    render() {
        return (
            <div className="profile-edit">
                <NavigationBar title={Translator.get("profile_title")} buttonsRight={
                    <div onClick={this.onCancel.bind(this)}>{Translator.get("cancel")}</div>
                } buttonsLeft={
                    <div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>
                }/>
                {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="profile-content">
                    <Input type="text" 
                        className="profile-edit-input" 
                        value={this.state.profile.first_name} 
                        onChange={this.onValueChange.bind(this, "first_name")}
                        label={Translator.get("first_name")}
                        error={this.state.invalids.first_name ? "" : null}>
                    </Input>
                    <Input type="text" 
                        className="profile-edit-input"
                        value={this.state.profile.last_name} 
                        onChange={this.onValueChange.bind(this, "last_name")}
                        label={Translator.get("last_name")}
                        error={this.state.invalids.last_name ? "" : null}>
                    </Input>
                    <Input type="text" 
                        className="profile-edit-input"
                        value={this.state.profile.email} 
                        onChange={this.onValueChange.bind(this, "email")}
                        label={Translator.get("email")}
                        error={this.state.invalids.email ? "" : null}>
                    </Input>
                    <Input type="text" 
                        className="profile-edit-input"
                        value={this.state.profile.phone_number} 
                        onChange={this.onValueChange.bind(this, "phone_number")}
                        label={Translator.get("phone_number")}
                        error={this.state.invalids.phone_number ? "" : null}>
                    </Input>
                    <Input type="date" 
                        className="profile-edit-input"
                        value={this.state.profile.birthdate} 
                        onChange={this.onValueChange.bind(this, "birthdate")}
                        label={Translator.get("birthdate")}
                        error={this.state.invalids.birthdate ? "" : null}>
                    </Input>
                    <Input type="text" 
                        className="profile-edit-input"
                        value={this.state.profile.address} 
                        onChange={this.onValueChange.bind(this, "address")}
                        label={Translator.get("address")}
                        error={this.state.invalids.address ? "" : null}>
                    </Input>
                    <Input type="text" 
                        className="profile-edit-input"
                        value={this.state.profile.zip_code} 
                        onChange={this.onValueChange.bind(this, "zip_code")}
                        label={Translator.get("zip_code")}
                        error={this.state.invalids.zip_code ? "" : null}>
                    </Input>
                    <Input type="text" 
                        className="profile-edit-input"
                        value={this.state.profile.locality} 
                        onChange={this.onValueChange.bind(this, "locality")}
                        label={Translator.get("locality")}
                        error={this.state.invalids.locality ? "" : null}>
                    </Input>
                </div>
            </div>
        );
    }

}

export default ProfileEdit;