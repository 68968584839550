class Translator {

    loadLanguage(lang) {
        var _this = this;
        var url = process.env.PUBLIC_URL + "/assets/lang/"+lang+".json";
        fetch(url)
            .then(response => response.json())
            .then(json => {
                _this.language = json;
            }
        );
    }

    isLoaded() {
        return this.language !== undefined;
    }

    get(word) {
        if (!this.language || !this.language[word]) {
            return word;
        }
        return this.language[word];
    }
}

export default new Translator();