import React, { Component } from 'react';
import './ClubController.css';

import NavigationBar from '../../../components/navigation-bar/NavigationBar.js'

import ListViewItem from '../../../components/list-view/ListViewItem.js'
import Translator from '../../scripts/Translator';
import LocationController from './locations/LocationController';
import ExcelController from './excel/ExcelController';
import SeasonController from './season/SeasonController';
import BoardController from './board/BoardController';
import SponsorsController from './sponsors/SponsorsController';
import FolderController from './files/FolderController';


class ClubController extends Component {

    constructor(props) {
        super(props);
        this.ac = this.props.context.user.role.access_control;
        this.state = {view: "menu"};
    }

    onBack() {
        this.setState({view: "menu"});
    }

    onLocationClick() {
        this.setState({view: "locations"});
    }

    onExcelClick() {
        this.setState({view: "excel"});
    }

    onSeasonClick() {
        this.setState({view: "season"});
    }

    onBoardClick() {
        this.setState({view: "board"});
    }

    onSponsorsClick() {
        this.setState({view: "sponsors"});
    }

    onFilesClick() {
        this.setState({view: "files"});
    }

    render() {
        if (this.state.view === "menu") {
            return (
                <div>
                    <NavigationBar title={Translator.get("club_title")}/>
                    <div className="club-controller-list-view">
                        {this.ac.edit_files     && <ListViewItem onClick={this.onFilesClick.bind(this)}    text={Translator.get("files_title")}     disclosureIndicator/>}
                        {this.ac.view_all_teams && <ListViewItem onClick={this.onExcelClick.bind(this)}    text={Translator.get("excel_title")}     disclosureIndicator/>}
                        {this.ac.edit_all_teams && <ListViewItem onClick={this.onSeasonClick.bind(this)}   text={Translator.get("season_title")}    disclosureIndicator/>}
                        {this.ac.edit_locations && <ListViewItem onClick={this.onLocationClick.bind(this)} text={Translator.get("locations_title")} disclosureIndicator/>}
                        {this.ac.edit_sponsors  && <ListViewItem onClick={this.onSponsorsClick.bind(this)} text={Translator.get("sponsors_title")}  disclosureIndicator/>}
                        {this.ac.edit_board     && <ListViewItem onClick={this.onBoardClick.bind(this)}    text={Translator.get("board_title")}     disclosureIndicator/>}
                    </div>
                </div>            
            );
        }
        else if (this.state.view === "locations") {
            return <LocationController onBack={this.onBack.bind(this)}></LocationController>;
        }
        else if (this.state.view === "excel") {
            return <ExcelController onBack={this.onBack.bind(this)}></ExcelController>;
        }
        else if (this.state.view === "season") {
            return <SeasonController onBack={this.onBack.bind(this)}></SeasonController>;
        }
        else if (this.state.view === "board") {
            return <BoardController onBack={this.onBack.bind(this)}></BoardController>;
        }
        else if (this.state.view === "sponsors") {
            return <SponsorsController onBack={this.onBack.bind(this)}></SponsorsController>;
        }
        else if (this.state.view === "files") {
            return <FolderController onBack={this.onBack.bind(this)}></FolderController>
        }
    }
}

export default ClubController;