import React, { Component } from 'react';
import './UserGuideStyles.css'
import Teams from '../../images/ug-teams.png'
import TeamDetail from '../../images/ug-team-detail.png'

class UserGuideEditTeam extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <div>
            <h1 className="user-guide-title">Team bearbeiten</h1>
            <p className="user-guide-warning">Du kannst nur diejenigen Teams bearbeiten bei welchen du als Trainer eingetragen bist.</p>
            <h2 className="user-guide-section">Schritt 1: Team auswählen</h2>
            <p>Öffne die Webapp und klicke unten auf den "Teams" Tab. Wähle das Team welches du bearbeiten möchtest.</p>
            <img className="user-guide-image-mobile" src={Teams} alt="Teamübersicht"></img>
            <div className="user-guide-image-description">Teamübersicht</div>

            <h2 className="user-guide-section">Schritt 2: Team bearbeiten</h2>
            <p>Auf der nun geöffneten Seite können das Bild und der Teamname bearbeitet werden. 
                Ausserdem kann gewählt werden, ob die Mannschaft auf der Webseite angezeigt werden soll oder nicht.</p>
            <img className="user-guide-image-mobile" src={TeamDetail} alt="Team Detailansicht"></img>
            <div className="user-guide-image-description">Team Detailansicht</div>
            <p>Von hier aus können Spieler, Trainer oder Trainingseinheiten hinzugefügt werden. Siehe dazu 
                <span className="user-guide-link" onClick={() => this.props.navigate("add_player")}> Spieler hinzufügen</span>,
                <span className="user-guide-link" onClick={() => this.props.navigate("add_coach")}> Trainer hinzufügen</span> und 
                <span className="user-guide-link" onClick={() => this.props.navigate("add_training")}> Training hinzufügen</span>.
            </p>

            <h2 className="user-guide-section">Schritt 3: Team speichern</h2>
            <p>Klicke oben links auf "Speichern" und die geänderten Daten werden gespeichert.</p>
        </div>;
    }

}

export default UserGuideEditTeam;