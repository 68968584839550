import React, { Component } from 'react';
import './PlayerEdit.css'
import Translator from '../../../scripts/Translator';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Input from '../../../../components/input/Input';

class PlayerEdit extends Component {

    constructor(props) {
        super(props);
        var m = props.member;
        this.state = {
            id: m.id,
            first_name: m.first_name || "",
            last_name: m.last_name || "",
            email: m.email || "",
            phone_number: m.phone_number || "",
            birthdate: m.birthdate || "",
            address: m.address || "",
            zip_code: m.zip_code || "",
            locality: m.locality || "",
            license_number: m.license_number || "",
            invalids: {}
        };
        this.class = "";
        if (this.props.member.has_user) {
            this.class = " static";
        }
    }

    onValueChanged(key, e) {
        if ((this.props.static || this.props.member.has_user) && key !== "license_number") return;
        var s = {};
        s[key] = e.target.value;
        this.setState(s);
    }

    onSaveClick() {
        var didError = false;
        var invalids = {
            first_name: false,
            last_name: false,
            email: false,
            phone_number: false,
            birthdate: false,
            address: false,
            zip_code: false,
            locality: false,
            license_number: false
        };
        if (this.state.first_name.length === 0) {
            didError = true;
            invalids.first_name = true;
        }
        if (this.state.last_name.length === 0) {
            didError = true;
            invalids.last_name = true;
        }
        if (this.state.address.length === 0) {
            didError = true;
            invalids.address = true;
        }
        if (this.state.zip_code.length === 0) {
            didError = true;
            invalids.zip_code = true;
        }
        if (this.state.locality.length === 0) {
            didError = true;
            invalids.locality = true;
        }
        if (this.state.birthdate.length === 0) {
            didError = true;
            invalids.birthdate = true;
        }
        var emailSplitted = this.state.email.split("@");
        if (emailSplitted.length !== 2) {
            didError = true;
            invalids.email = true;
        }
        else {
            emailSplitted = emailSplitted[1].split(".");
            if (emailSplitted.length === 1) {
                didError = true;
                invalids.email = true;
            }
        }
        var phoneNumber = this.state.phone_number;
        if (phoneNumber.length !== 13) { // 000_000_00_00
            if (!phoneNumber) {
                didError = true;
                invalids.phone_number = true;
            }
            else {
                phoneNumber = this.state.phone_number.slice(0,3) + " " + this.state.phone_number.slice(3,6) + " " + this.state.phone_number.slice(6,8)
                    + " " + this.state.phone_number.slice(8,10);
                if (phoneNumber.length !== 13) {
                    didError = true;
                    invalids.phone_number = true;
                }
                this.setState({phone_number: phoneNumber});
            }            
        }
        if (didError) {
            this.setState({invalids: invalids});
            return;
        }
        this.props.onSave({
            id: this.state.id,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            phone_number: phoneNumber,
            birthdate: this.state.birthdate,
            address: this.state.address,
            zip_code: this.state.zip_code,
            locality: this.state.locality,
            license_number: this.state.license_number
        });
    }

    onBackClick() {
        this.props.onBack();
    }

    onResignClick() {
        this.props.onResign(this.state.id);
    }

    render() {
        return (
            <div className="player-edit">
                <NavigationBar title={Translator.get("member")} buttonsLeft={<div onClick={this.onBackClick.bind(this)}>{this.props.backButton}</div>} 
                    buttonsRight={<div onClick={this.onSaveClick.bind(this)}>{this.props.saveButton}</div>}/>
                <div className={"player-edit-content" + this.class}>
                    {this.props.member.has_user &&
                    <div className="player-edit-message">{Translator.get("static_message")+this.state.first_name + " " + this.state.last_name}</div>}
                    <Input type="text" className="player-edit-input"
                        error={this.state.invalids.first_name ? "" : null}
                        value={this.state.first_name} onChange={this.onValueChanged.bind(this, "first_name")}
                        label={Translator.get("first_name")}>
                    </Input>
                    <Input type="text" className="player-edit-input"
                        error={this.state.invalids.last_name ? "" : null}
                        value={this.state.last_name} onChange={this.onValueChanged.bind(this, "last_name")}
                        label={Translator.get("last_name")}>
                    </Input>
                    <Input type="text" className="player-edit-input"
                        error={this.state.invalids.email ? "" : null}
                        value={this.state.email} onChange={this.onValueChanged.bind(this, "email")}
                        label={Translator.get("email")}>
                    </Input>
                    <Input type="text" className="player-edit-input"
                        error={this.state.invalids.phone_number ? "" : null}
                        value={this.state.phone_number} onChange={this.onValueChanged.bind(this, "phone_number")}
                        label={Translator.get("phone_number")}>
                    </Input>
                    <Input type="date" className="player-edit-input"
                        error={this.state.invalids.birthdate ? "" : null}
                        value={this.state.birthdate} onChange={this.onValueChanged.bind(this, "birthdate")}
                        label={Translator.get("birthdate")}>
                    </Input>
                    <Input type="text" className="player-edit-input"
                        error={this.state.invalids.address ? "" : null}
                        value={this.state.address} onChange={this.onValueChanged.bind(this, "address")}
                        label={Translator.get("address")}>
                    </Input>
                    <Input type="text" className="player-edit-input"
                        error={this.state.invalids.zip_code ? "" : null}
                        value={this.state.zip_code} onChange={this.onValueChanged.bind(this, "zip_code")}
                        label={Translator.get("zip_code")}>
                    </Input>
                    <Input type="text" className="player-edit-input"
                        error={this.state.invalids.locality ? "" : null}
                        value={this.state.locality} onChange={this.onValueChanged.bind(this, "locality")}
                        label={Translator.get("locality")}>
                    </Input>
                    <Input type="text" className="player-edit-input license-number" value={this.state.license_number} 
                        onChange={this.onValueChanged.bind(this, "license_number")}
                        label={Translator.get("license_number")}>
                    </Input>
                    {this.props.canRemove && 
                        <div onClick={this.onResignClick.bind(this)} className="player-edit-remove">{this.props.resignTitle}</div>}
                </div>
            </div>
        );
    }
}

export default PlayerEdit;