import React, { Component } from 'react';
import './EventAdd.css'
import NavigationBar from '../../../components/navigation-bar/NavigationBar';
import Translator from '../../scripts/Translator';
import ListViewItem from '../../../components/list-view/ListViewItem';
import Api from '../../scripts/Api';
import Popup from '../../../components/popup/Popup';
import ActivityIndicator from '../../../components/activity-indicator/ActivityIndicator';
import EventNewCustom from './EventNewCustom';
import BackIcon from '../../../images/back.png';
import EventAddPredefined from './EventAddPredefined';

class EventAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "menu"
        };
    }

    componentDidMount() {
        this.setState({loading: true, error: null});
        Api.get("/teams", res => {
            this.teams = res.map((t, i) => <ListViewItem key={i} disclosureIndicator text={t.name} onClick={this.onSelectTeam.bind(this, t)}></ListViewItem>);
            this.setState({loading: false});
        }, e => {
            this.setState({loading: false, error: e.error});
        }, 600);
    }

    addNewEvent() {
        this.setState({view: "new-custom"});
    }

    onSelectTeam(team) {
        this.selectedTeam = team;
        this.setState({view: "add-predefined"});
    }

    onCancel() {
        this.props.onBack();
    }

    onChildBack() {
        this.setState({view: "menu"});
    }

    onEventAdded() {
        this.onChildBack();
        this.props.onEventAdded();
    }

    render() {
        if (this.state.view === "new-custom") {
            return <EventNewCustom onBack={this.onChildBack.bind(this)} onEventAdded={this.onEventAdded.bind(this)}></EventNewCustom>;
        }
        if (this.state.view === "add-predefined") {
            return <EventAddPredefined team={this.selectedTeam} onBack={this.onChildBack.bind(this)} onEventAdded={this.onEventAdded.bind(this)}></EventAddPredefined>
        }
        return <div className="event-add">
            <NavigationBar title={Translator.get("new_event")} buttonsLeft={
                <img onClick={this.onCancel.bind(this)} src={BackIcon} alt="back"></img>
            }/>
            <div className="event-add-content">
                {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <ListViewItem disclosureIndicator text={Translator.get("new_event")} onClick={this.addNewEvent.bind(this)}></ListViewItem>
                <div className="event-add-seperator">{Translator.get("new_event_special")}</div>
                {this.teams}
            </div>
        </div>;
    }

}

export default EventAdd;