class Router {

    constructor() {
        window.onpopstate = this._onStateChanged.bind(this);
        var path = window.location.pathname.split("/");
        path.splice(0, 1);
        this.path = path;
        this.id = 0;
        this.subscribers = {};
    }

    addChangeListener(listener) {
        this.id++;
        this.subscribers[this.id] = listener;
        listener();
        return this.id;
    }

    removeChangeListener(id) {
        delete this.subscribers[id];
    }

    replacePath(path) {
        this.path = path;
        window.history.pushState({}, "", "/"+path.join("/"));
        this._dispatchEvent();
    }

    setPath(path, level) {
        this.path[level] = path;
        this.path.splice(level+1, this.path.length);
        window.history.pushState({}, "", "/"+this.path.join("/"));
        this._dispatchEvent();
    }

    getPath(level) {
        return this.path[level];
    }

    _onStateChanged(e) {
        var path = window.location.pathname.split("/");
        path.splice(0, 1);
        this.path = path;
        this._dispatchEvent();
    }

    _dispatchEvent() {
        for (var key in this.subscribers) {
            this.subscribers[key]();
        }
    }

}

export default new Router();