import React, { Component } from 'react';
import './FilesController.css'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import Translator from '../../../scripts/Translator';
import AddIcon from '../../../../images/add.png';
import BackIcon from '../../../../images/back.png';
import FileDetail from './FileDetail';
import Api from '../../../scripts/Api';
import ListViewItem from '../../../../components/list-view/ListViewItem';

class FilesController extends Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "menu"
        };
    }

    componentDidMount() {
        this.loadFiles(60*15);
    }

    loadFiles(cache) {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.get("/files/"+this.props.folderId, body => {
            _this.folderElements = body.map((f, i) => <ListViewItem key={i} text={f.name} disclosureIndicator onClick={this.onFileClicked.bind(this, f)}/>);
            _this.setState({loading: false});
        }, error => {
            _this.setState({error: error.error, loading: false});
        }, cache);
    }

    onFileClicked(f) {
        this.selectedFile = f;
        this.setState({view: "detail"});
    }

    onAddClick() {
        this.selectedFile = null;
        this.setState({view: "detail"});
    }

    onBackClick() {
        this.props.onBack();
    }

    onChildBack() {
        this.setState({view: "menu"});
    }

    onFileAdded() {
        this.loadFiles(0);
        this.onChildBack();
    }

    render() {
        if (this.state.view === "detail") {
            return <FileDetail folderId={this.props.folderId} file={this.selectedFile} onBack={this.onChildBack.bind(this)} onFileAdded={this.onFileAdded.bind(this)}/>;
        }
        return (
            <div className="files-controller">
                <NavigationBar title={Translator.get("files_title")} buttonsRight={
                    <img src={AddIcon} alt="Add File" onClick={this.onAddClick.bind(this)}></img>
                } buttonsLeft={
                    <img src={BackIcon} alt="Back" onClick={this.onBackClick.bind(this)}></img>
                }/>
                {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="files-controller-content">
                    {this.folderElements}
                </div>
            </div>
        );
    }

}

export default FilesController;