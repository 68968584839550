import React, { Component } from 'react';
import './EventController.css'
import NavigationBar from '../../../components/navigation-bar/NavigationBar';
import Translator from '../../scripts/Translator';
import AddIcon from '../../../images/add.png'
import GridIcon from '../../../images/grid-icon.png'
import ListIcon from '../../../images/list-icon.png'
import EventAdd from './EventAdd';
import Api from '../../scripts/Api';
import Popup from '../../../components/popup/Popup';
import ActivityIndicator from '../../../components/activity-indicator/ActivityIndicator';
import EventDetail from './EventDetail';
import EventDetailPopup from './EventDetailPopup';
import AcceptIcon from '../../../images/event-accept.png';
import AcceptIconInactive from '../../../images/event-accept-inactive.png';
import DeclineIcon from '../../../images/event-decline.png';

class EventController extends Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "menu",
            viewMode: "list",
            gridDetail: false
        };
        this.ac = this.props.context.user.role.access_control;
        this.viewModeIcon = GridIcon;
        this.contentNode = React.createRef();
    }

    componentDidMount() {
        this.loadEvents(600);
    }

    loadEvents(cache) {
        this.setState({loading: true, error: null});
        Api.get("/events", res => {
            this.events = res;
            this.eventElements = res.map(e => {
                var date = new Date(e.date).toLocaleDateString("de-DE", {weekday: "short", year: "numeric", month: "2-digit", day: "2-digit"});
                var icon = AcceptIcon;
                if (e.answer === 0) {
                    icon = DeclineIcon;
                }
                if (e.answer === -1) {
                    icon = AcceptIconInactive;
                }
                return <div key={e.id} className="event-controller-event-wrapper">
                    <div className="event-controller-event" onClick={this.onEventClicked.bind(this, e)}>
                        <div className="event-controller-event-row">
                            <div className="event-controller-event-row-title">{e.title}</div>
                            <div>{date}</div>
                        </div>
                        <div className="event-controller-event-row"><div>{e.location}</div><div>{e.start_time + " - " + e.end_time}</div></div>
                    </div>
                    <div className="event-controller-event-icon-wrapper">
                        <img src={icon} alt=""></img>
                    </div>
                </div>;
            });
            // find first event in the future        
            var now = new Date();
            now.setDate(now.getDate() - 1);
            for (var startIndex = 0; startIndex < this.events.length; startIndex++) {
                let date = new Date(this.events[startIndex].date);
                if (date >= now) {
                    break;
                }
            }
            this.setState({loading: false});
            if (this.contentNode.current) {
                setTimeout(() => {
                    this.contentNode.current.scrollTop = 59*startIndex;
                }, 10);
            }
        }, e => {
            this.setState({loading: false, error: e.error});
        }, cache);
        Api.get("/profile", res => {
            this.profile = res;
        }, e => {}, 600);
    }

    onEventClicked(event) {
        this.selectedEvent = event;
        this.setState({view: "detail"});
    }

    onAddClick() {
        this.setState({view: "add"});
    }

    onChildBack() {
        this.setState({view: "menu"});
        setTimeout(() => {
            if (this.contentNode.current) {
                var now = new Date();
                now.setDate(now.getDate() - 1);
                for (var startIndex = 0; startIndex < this.events.length; startIndex++) {
                    let date = new Date(this.events[startIndex].date);
                    if (date >= now) {
                        break;
                    }
                }
                setTimeout(() => {
                    this.contentNode.current.scrollTop = 59*startIndex;
                }, 10);
            }
        }, 100);
    }

    onEventAdded() {
        this.setState({gridDetail: false});
        this.loadEvents(0);
        this.gridElements = null;
        if (this.state.viewMode === "grid") {
            this.loadGridView();
        }
        this.onChildBack();
    }

    onViewModeToggle() {
        if (this.state.viewMode === "list") {
            this.viewModeIcon = ListIcon;
            this.setState({viewMode: "grid", gridDetail: false});
            this.loadGridView();
        }
        else {
            this.viewModeIcon = GridIcon;
            this.setState({viewMode: "list", gridDetail: false});
            setTimeout(() => {
                if (this.contentNode.current) {
                    var now = new Date();
                    now.setDate(now.getDate() - 1);
                    for (var startIndex = 0; startIndex < this.events.length; startIndex++) {
                        let date = new Date(this.events[startIndex].date);
                        if (date >= now) {
                            break;
                        }
                    }
                    setTimeout(() => {
                        this.contentNode.current.scrollTop = 59*startIndex;
                    }, 10);
                }
            }, 100);
        }
    }

    loadGridView() {
        if (this.gridElements) return;
        this.setState({loading: true, error: null});
        // find first event in the future        
        var now = new Date();
        now.setDate(now.getDate() - 1);
        for (var startIndex = 0; startIndex < this.events.length; startIndex++) {
            let date = new Date(this.events[startIndex].date);
            if (date >= now) {
                break;
            }
        }
        var inFlight = Math.min(this.events.length-startIndex, 50);
        var eventAnswers = {};
        var idMap = {};
        var successFunc = function(index, answers) {
            for (var j = 0; j < answers.length; j++) {
                var key = answers[j].first_name + " " + answers[j].last_name;
                idMap[key] = answers[j].member_id;
                if (!eventAnswers[key]) {
                    eventAnswers[key] = [];
                }
                eventAnswers[key][index] = {answer: answers[j].answer, comment: answers[j].comment};
            }
            inFlight -= 1;
            if (inFlight === 0) {
                this.createGridView(eventAnswers, idMap, startIndex);
                this.setState({loading: false, error: null});
            }
        };
        var errFunc = function(e) {
            this.setState({loading: false, error: e});
        };
        for (let i = startIndex; i < this.events.length && i < startIndex+50; i++) {
            Api.get("/events/"+this.events[i].id+"/answers", successFunc.bind(this, i), errFunc, 600);
        }        
    }

    createGridView(answers, idMap, startIndex) {
        var gridEventElements = [];
        for (let i = startIndex; i < this.events.length && i < startIndex+50; i++) {
            var date = new Date(this.events[i].date).toLocaleDateString("de-DE", {weekday: "short", year: "numeric", month: "2-digit", day: "2-digit"});
            gridEventElements.push(
                <div className="event-controller-grid-event" key={i}>
                    <div className="event-controller-grid-event-title">{this.events[i].title}</div>
                    <div className="event-controller-grid-event-location">{this.events[i].location}</div>
                    <div className="event-controller-grid-event-date">{date}</div>
                    <div className="event-controller-grid-event-time">{this.events[i].start_time+"-"+this.events[i].end_time}</div>
                </div>
            );
        }
        var gridElements = [];
        var nameElements = [];
        for (var key in answers) {
            var splittedName = key.split(" ");
            nameElements.push(<div key={key} className="event-controller-grid-member">{splittedName[0] + " " + splittedName[1].substr(0,1) + "."}</div>);
            var rowData = [];
            var as = answers[key];
            for (let i = startIndex; i < this.events.length && i < startIndex+50; i++) {
                var className = "not-invited";
                if (as[i] && as[i].answer === 1) {
                    className = "yes-answer";
                }
                else if (as[i] && as[i].answer === 0) {
                    className = "no-answer";
                }
                else if (as[i] && as[i].answer === -1) {
                    className = "empty-answer";
                }
                rowData.push(
                    <div key={i} className={"event-controller-grid-answer "+className} onClick={this.onGridAnswerClicked.bind(this, this.events[i], as[i], key, idMap[key])}>
                        {as[i] && as[i].comment}
                    </div>
                );
            }
            gridElements.push(<div key={key} className="event-controller-grid-row">{rowData}</div>);
        }
        this.gridElements = gridElements;
        this.nameElements = nameElements;
        this.gridEventElements = gridEventElements;
    }

    onGridAnswerClicked(event, answer, name, memberId) {
        if (!answer) return;
        if (!this.ac.edit_related_answers && !this.ac.edit_all_answers && memberId !== this.profile.member.id) {
            return;
        }
        this.gridClickedEvent = event;
        this.gridClickedAnswer = answer;
        this.gridClickedMember = {name: name, id: memberId};
        this.setState({gridDetail: true});
    }

    render() {
        if (this.state.view === "detail") {
            return <EventDetail onBack={this.onChildBack.bind(this)} onEventSaved={this.onEventAdded.bind(this)} event={this.selectedEvent} context={this.props.context}></EventDetail>
        }
        if (this.state.view === "add") {
            return <EventAdd onBack={this.onChildBack.bind(this)} onEventAdded={this.onEventAdded.bind(this)}></EventAdd>;
        }
        return <div className="event-controller">
            <NavigationBar title={Translator.get("events_title")} buttonsRight={
                this.ac.edit_all_events && <img src={AddIcon} alt="Add Team Button" onClick={this.onAddClick.bind(this)}></img>
            } buttonsLeft={
                <img src={this.viewModeIcon} alt="Toggle View" onClick={this.onViewModeToggle.bind(this)}></img>
            }/>
            <div className="event-controller-content" ref={this.contentNode}>
                {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                {this.state.gridDetail &&   
                    <EventDetailPopup event={this.gridClickedEvent} answer={this.gridClickedAnswer} member={this.gridClickedMember} onEventSaved={this.onEventAdded.bind(this)}>
                    </EventDetailPopup>
                }
                {this.state.viewMode==="list" && this.eventElements}
                {this.state.viewMode==="grid" && 
                    <div className="event-controller-grid">
                        <div className="event-controller-grid-names">
                            {this.nameElements}
                        </div>
                        <div className="event-controller-grid-elements">
                            <div className="event-controller-grid-events">{this.gridEventElements}</div>
                            {this.gridElements}
                        </div>
                    </div>
                }
            </div>
        </div>;
    }

}

export default EventController;