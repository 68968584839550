import React, { Component } from 'react';
import './PlayerSearch.css'
import Translator from '../../../scripts/Translator';
import Popup from '../../../../components/popup/Popup';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import PlayerCell from './PlayerCell'

import BackIcon from '../../../../images/back.png';
import Api from '../../../scripts/Api';
import ListViewItem from '../../../../components/list-view/ListViewItem';
import PlayerEdit from './PlayerEdit';
import Input from '../../../../components/input/Input';

class PlayerSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            firstName: "",
            lastName: "",
            view: "overview"
        };
    }

    onBackClick() {
        this.props.onBack();
    }

    onFirstNameChange(e) {
        this.setState({firstName: e.target.value});
    }

    onLastNameChange(e){
        this.setState({lastName: e.target.value});
    }

    onSearchClick() {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.get("/members", members => {
            _this.memberCells = members.map((m, i) => <PlayerCell key={i} member={m} onClick={this.onMemberClick.bind(this, m)}></PlayerCell>);
            _this.setState({loading: false})
        }, e => {
            _this.setState({loading: false, error: e.error});
        }, 0, {first_name: this.state.firstName, last_name: this.state.lastName});
    }

    onMemberClick(m) {
        this.selectedMember = m;
        this.setState({view: "player-view"});        
    }

    onAddMemberClick() {
        this.selectedMember = {first_name: this.state.firstName, last_name: this.state.lastName};
        this.setState({view: "player-add"});
    }

    onDetailCancel() {
        this.setState({view: "overview"});
    }

    onSaveNewMember(member) {
        this.setState({loading: true, error: null});
        var _this = this;
        if (!member.id) {
            Api.post("/members", member, res => {
                Api.post("/teams/"+_this.props.teamID+"/players", {id: res.id}, () => {
                    _this.props.onBack();
                }, e => {
                    _this.setState({loading: false, error: e.error});
                });            
            }, e => {
                _this.setState({loading:false, error: e.error});
            });
        }
        else {
            Api.post("/teams/"+this.props.teamID+"/players", {id: member.id}, () => {
                _this.props.onBack();
            }, e => {
                _this.setState({loading: false, error: e.error});
            });
        }        
    }

    render() {
        if (this.state.view === "player-add") {
            return (
                <div>
                    <PlayerEdit member={this.selectedMember} backButton={Translator.get("cancel")} saveButton={Translator.get("save")} 
                        onBack={this.onDetailCancel.bind(this)} onSave={this.onSaveNewMember.bind(this)}></PlayerEdit>
                    {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                    <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                </div>
            );
        }
        if (this.state.view === "player-view") {
            return (
                <div>
                    <PlayerEdit member={this.selectedMember} backButton={Translator.get("cancel")} saveButton={Translator.get("add")} 
                        onBack={this.onDetailCancel.bind(this)} onSave={this.onSaveNewMember.bind(this)} static></PlayerEdit>
                    {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                    <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                </div>
            );
        }
        var content;
        if (this.state.loading) {
            content = (<Popup><ActivityIndicator/></Popup>);
        }
        return (
            <div className="player-search">
                <NavigationBar title={Translator.get("new_player")} buttonsLeft={
                    <img src={BackIcon} alt="Back" onClick={this.onBackClick.bind(this)}></img>
                }/>
                {content}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="player-search-input">
                    <Input type="text" className="player-search-firstname" 
                        value={this.state.firstName} 
                        onChange={this.onFirstNameChange.bind(this)}
                        label={Translator.get("first_name")}>
                    </Input>
                    <Input type="text" className="player-search-lastname" 
                        value={this.state.lastName} 
                        onChange={this.onLastNameChange.bind(this)}
                        label={Translator.get("last_name")}>
                    </Input>
                    <div className="player-search-button" onClick={this.onSearchClick.bind(this)}>{Translator.get("search")}</div>
                </div>
                <div className="player-search-result">
                    {this.memberCells && 
                        <ListViewItem text={Translator.get("new_player")} disclosureIndicator onClick={this.onAddMemberClick.bind(this)}></ListViewItem>}                    
                    {this.memberCells}
                </div>
            </div>
        );
    }
}

export default PlayerSearch;