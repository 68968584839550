import React, { Component } from 'react';
import './LocationDetail.css';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../scripts/Translator';
import Checkbox from '../../../../components/checkbox/Checkbox';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import Popup from '../../../../components/popup/Popup';
import Api from '../../../scripts/Api';
import Input from '../../../../components/input/Input';


class LocationDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: props.location.name || "",
            longitude: props.location.longitude || "",
            latitude: props.location.latitude || "",
            public: props.location.public || false,
            id: props.location.id
        };
    }

    onCancel() {
        this.props.onBack();
    }

    onSave() {
        var body = {
            name: this.state.name,
            longitude: Number(this.state.longitude),
            latitude: Number(this.state.latitude),
            public: this.state.public
        };
        this.setState({loading: true, error: null});
        var _this = this;
        if (this.state.id) {
            Api.put("/locations/"+this.state.id, body, () => {
                _this.setState({loading: false});
                _this.props.onLocationAdd();
            }, error => {
                _this.setState({loading: false, error: error.error});
            });
        }
        else {
            Api.post("/locations", body, () => {
                _this.setState({loading: false});
                _this.props.onLocationAdd();
            }, error => {
                _this.setState({loading: false, error: error.error});
            });
        }
    }

    onDeleteClick() {
        var _this = this;
        Api.delete("/locations/"+this.state.id, {}, () => {
            _this.setState({loading: false});
            _this.props.onLocationAdd();
        }, error => {
            _this.setState({loading: false, error: error.error});
        });
    }

    onNameChange(e) {
        this.setState({name: e.target.value});
    }

    onLongitudeChange(e) {
        this.setState({longitude: e.target.value});
    }

    onLatitudeChange(e) {
        this.setState({latitude: e.target.value});
    }

    onPublicChange(e) {
        this.setState({public: !this.state.public});
    }

    render() {
        return (
            <div className="location-detail">
                <NavigationBar title={this.state.name} buttonsLeft={
                    <div onClick={this.onCancel.bind(this)}>{Translator.get("cancel")}</div>
                } buttonsRight={
                    <div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>
                }/>
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                {this.state.loading && <Popup><ActivityIndicator></ActivityIndicator></Popup>}
                <div className="location-detail-content">
                    <Input type="text" className="location-detail-input" value={this.state.name} 
                        onChange={this.onNameChange.bind(this)}
                        label={Translator.get("location_name")}>
                    </Input>
                    <Input type="text" className="location-detail-input" value={this.state.longitude} 
                        onChange={this.onLongitudeChange.bind(this)}
                        label={Translator.get("location_longitude")}>
                    </Input>
                    <Input type="text" className="location-detail-input" value={this.state.latitude} 
                        onChange={this.onLatitudeChange.bind(this)}
                        label={Translator.get("location_latitude")}>
                    </Input>
                    <div className="location-detail-checkbox">
                        <Checkbox checked={this.state.public} onCheck={this.onPublicChange.bind(this)}/>
                        <div className="location-detail-checkbox-label">{Translator.get("display_on_webpage")}</div>
                    </div>    
                    {this.state.id && 
                        <div onClick={this.onDeleteClick.bind(this)} className="location-detail-remove">{Translator.get("delete")}</div>}                
                </div>
            </div>
        );
    }
}

export default LocationDetail;