import React, { Component } from 'react';
import './MemberRequest.css'
import VolleyLogo from "../../images/volley-logo-black.png"
import Api from '../scripts/Api';
import Popup from '../../components/popup/Popup';
import ActivityIndicator from '../../components/activity-indicator/ActivityIndicator';
import Checkbox from '../../components/checkbox/Checkbox';

class MemberRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            birthdate: "",
            address: "",
            zip_code: "",
            locality: "",
            privacyAccepted: false,
            done: false
        };
    }

    onValueChanged(key, e) {
        var s = {showPrivacy: false};
        s[key] = e.target.value;
        this.setState(s);
    }

    onSaveClick() {
        var didError = false;
        var classes = {
            first_name_class: "",
            last_name_class: "",
            email_class: "",
            phone_number_class: "",
            birthdate_class: "",
            address_class: "",
            zip_code_class: "",
            locality_class: "",
            privacy_accepted_class: ""
        };
        if (!this.state.privacyAccepted) {
            didError = true;
            classes.privacy_accepted_class = "invalid";
        }
        if (this.state.first_name.length === 0) {
            didError = true;
            classes.first_name_class = "invalid";
        }
        if (this.state.last_name.length === 0) {
            didError = true;
            classes.last_name_class = "invalid";
        }
        if (this.state.address.length === 0) {
            didError = true;
            classes.address_class = "invalid";
        }
        if (this.state.zip_code.length === 0) {
            didError = true;
            classes.zip_code_class = "invalid";
        }
        if (this.state.locality.length === 0) {
            didError = true;
            classes.locality_class = "invalid";
        }
        if (this.state.birthdate.length === 0) {
            didError = true;
            classes.birthdate_class = "invalid";
        }
        var emailSplitted = this.state.email.split("@");
        if (emailSplitted.length !== 2) {
            didError = true;
            classes.email_class = "invalid";
        }
        else {
            emailSplitted = emailSplitted[1].split(".");
            if (emailSplitted.length === 1) {
                didError = true;
                classes.email_class = "invalid";
            }
        }
        var phoneNumber = this.state.phone_number;
        if (phoneNumber.length !== 13) { // 000_000_00_00
            if (!phoneNumber) {
                didError = true;
                classes.phone_number_class = "invalid";
            }
            else {
                phoneNumber = this.state.phone_number.slice(0,3) + " " + this.state.phone_number.slice(3,6) + " " + this.state.phone_number.slice(6,8)
                    + " " + this.state.phone_number.slice(8,10);
                if (phoneNumber.length !== 13) {
                    didError = true;
                    classes.phone_number_class = "invalid";
                }
                this.setState({phone_number: phoneNumber});
            }            
        }
        if (didError) {
            this.setState(classes);
            return;
        }
        let _this = this;
        this.setState({loading: true});
        setTimeout(() => {
            Api.post("/member-requests", {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                phone_number: this.state.phone_number,
                birthdate: this.state.birthdate,
                address: this.state.address,
                zip_code: this.state.zip_code,
                locality: this.state.locality,
            }, res => {
                _this.setState({loading:false, error: null, done: true});
            }, e => {
                _this.setState({loading:false, error: e.error, done: false});
            });
        }, 500);
    }

    changeCheckbox() {
        this.setState({privacyAccepted: !this.state.privacyAccepted, showPrivacy: false});
    }

    showPrivacy() {
        this.setState({showPrivacy: true});
    }

    render() {
        if (this.state.done) {
            return <div className="member-request-wrapper">
                <div className="member-request">
                    <img className="member-request-logo" src={VolleyLogo} alt=""></img>
                    <h2 className="member-request-title">Mitgliedschaftsformular</h2>
                    <p className="member-request-confirmation">Danke, {this.state.first_name}, wir haben deine Angaben erhalten.</p>
                </div>
            </div>;
        }
        return <div className="member-request-wrapper">
            <div className="member-request">
                {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                <img className="member-request-logo" src={VolleyLogo} alt=""></img>
                <h2 className="member-request-title">Mitgliedschaftsformular</h2>
                <div className="member-request-label">Vorname</div>
                <input type="text" className={"member-request-input " + this.state.first_name_class}
                    value={this.state.first_name} onChange={this.onValueChanged.bind(this, "first_name")} placeholder="Max">
                </input>
                <div className="member-request-label">Nachname</div>
                <input type="text" className={"member-request-input " + this.state.last_name_class}
                    value={this.state.last_name} onChange={this.onValueChanged.bind(this, "last_name")} placeholder="Muster">
                </input>
                <div className="member-request-label">Email</div>
                <input type="text" className={"member-request-input " + this.state.email_class}
                    value={this.state.email} onChange={this.onValueChanged.bind(this, "email")} placeholder="max.muster@email.ch">
                </input>
                <div className="member-request-label">Telefonnummer / Handynummer</div>
                <input type="text" className={"member-request-input " + this.state.phone_number_class}
                    value={this.state.phone_number} onChange={this.onValueChanged.bind(this, "phone_number")} placeholder="078 777 33 11">
                </input>
                <div className="member-request-label">Geburtsdatum</div>
                <input type="date" className={"member-request-input " + this.state.birthdate_class}
                    value={this.state.birthdate} onChange={this.onValueChanged.bind(this, "birthdate")}>
                </input>
                <div className="member-request-label">Strasse</div>
                <input type="text" className={"member-request-input " + this.state.address_class}
                    value={this.state.address} onChange={this.onValueChanged.bind(this, "address")} placeholder="Musterstrasse 22b">
                </input>
                <div className="member-request-label">Postleitzahl</div>
                <input type="text" className={"member-request-input " + this.state.zip_code_class}
                    value={this.state.zip_code} onChange={this.onValueChanged.bind(this, "zip_code")} placeholder="6333">
                </input>
                <div className="member-request-label">Ort</div>
                <input type="text" className={"member-request-input " + this.state.locality_class}
                    value={this.state.locality} onChange={this.onValueChanged.bind(this, "locality")} placeholder="Hünenberg See">
                </input>
                {this.state.showPrivacy && <Popup closeButton="Okay">
                    <h4>Daten in der Sportdatenbank und deren Bearbeitung</h4> 
                    <p className="privacy">
                    Folgende Daten von Teilnehmenden der Jugendausbildung werden in der Datenbank erfasst: Name, Vorname, Geburtsdatum, Adresse, Email, Telefon, Angebot, an welchem teilgenommen wird. 
                    Die Daten dürfen ausser von den Organisatoren der Angebote, welche diese für das BASPO erfassen, nur durch die Organe des BASPO, die kantonalen J+S-Fachstellen sowie der für die Belange des Sport zuständigen kommunalen Behörden bearbeitet werden, sofern ihnen eine entsprechende Aufgabe in der Administration von J+S zukommt. 
                    Im Einzelfall ist das BASPO befugt, diese Daten Dritten bekannt zu geben, sofern die Daten zu nicht kommerziellen Zwecken verwendet werden. Jede Person hat das Recht, diese Weitergabe ihrer Personendaten an Dritte zu untersagen. Ein entsprechendes Musterschreiben findet sich auf der Homepage des Eidg. Datenschutzbeauftragten.
                    Die rechtlichen Grundlagen für die J+S-Datenbank bilden das Bundesgesetz über die Informationssysteme des Bundes im Bereich Sport (IBSG; SR 415.1)1 sowie die Verordnung über die Informationssysteme des Bundes im Bereich Sport (IBSV; SR 415.11)2.
                    </p>
                </Popup>}
                <div className={"member-request-checkbox " + this.state.privacy_accepted_class}>
                    <Checkbox checked={this.state.privacyAccepted} onCheck={this.changeCheckbox.bind(this)}></Checkbox>
                    <span>Ich akzeptiere die </span> <span className="anchor" onClick={this.showPrivacy.bind(this)}>Datenschutzerklärung</span>
                </div>
                <div className="member-request-button" onClick={this.onSaveClick.bind(this)}>Absenden</div>
            </div>
        </div>;
    }

}

export default MemberRequest;