import React, { Component } from 'react';
import './FileDetail.css'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import Translator from '../../../scripts/Translator';
import Checkbox from '../../../../components/checkbox/Checkbox';
import Api from '../../../scripts/Api';
import Input from '../../../../components/input/Input';

class FileDetail extends Component {

    constructor(props) {
        super(props);
        if (!this.props.file) {
            this.state = {
                name: "",
                public: false
            };
        }
        else {
            this.state = {
                id: props.file.id,
                name: props.file.name,
                public: props.file.public
            }
        }
    }

    onSave() {
        this.setState({loading: true});
        var body = new FormData();
        body.append("name", this.state.name);
        body.append("public", this.state.public);
        body.append("parent", this.props.folderId);
        body.append("file", this.state.file);
        if (this.state.id) {
            Api.putForm("/files/"+this.state.id, body, () => {
                this.setState({loading: false});
                this.props.onFileAdded();
            }, e => {
                this.setState({loading: false, error: e.error});
            });
        }
        else {
            Api.postForm("/files", body, () => {
                this.setState({loading: false});
                this.props.onFileAdded();
            }, e => {
                this.setState({loading: false, error: e.error});
            });
        }
    }

    onDelete() {
        if (this.state.id) {
            Api.delete("/files/"+this.state.id, {}, () => {
                this.setState({loading: false});
                this.props.onFileAdded();
            }, e => {
                this.setState({loading: false, error: e.error});
            });
        }
    }

    onCancel() {
        this.props.onBack();
    }

    onValueChanged(key, e) {
        var o = {};
        o[key] = e.target.value;
        this.setState(o);
    }

    onFileChanged(e) {
        this.setState({file: e.target.files[0]});
    }

    onPublicChange() {
        this.setState({public: !this.state.public});
    }

    render() {
        return (
            <div className="file-detail">
                <NavigationBar title={Translator.get("file_detail_title")} buttonsLeft={
                    <div onClick={this.onCancel.bind(this)}>{Translator.get("cancel")}</div>
                } buttonsRight={
                    <div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>
                }/>
                {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="file-detail-content">
                    <Input type="text" className="file-detail-input" value={this.state.name} 
                        onChange={this.onValueChanged.bind(this, "name")}
                        label={Translator.get("name")}>
                    </Input>
                    <div className="file-detail-checkbox-wrapper">
                        <Checkbox className="file-detail-checkbox" checked={this.state.public} onCheck={this.onPublicChange.bind(this)}/>
                        <span className="file-detail-checkbox-text">{Translator.get("display_on_webpage")}</span>
                    </div>
                    <Input type="file" className="file-detail-file-input" 
                        onChange={this.onFileChanged.bind(this)}
                        label={Translator.get("file")}>
                    </Input>
                    {this.state.id && <div className="file-detail-download-button">
                        <a className="file-detail-download-link" href={Api.baseUrl+"/files/"+this.state.id+"?access_token="+Api.at} target="_blank" rel="noopener noreferrer">
                            {Translator.get("view_file_button")}
                        </a>
                    </div>}
                    {this.state.id && 
                        <div onClick={this.onDelete.bind(this)} className="file-detail-remove">{Translator.get("delete")}</div>}
                </div>
            </div>
        );
    }

}

export default FileDetail;