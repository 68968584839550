import React, { Component } from 'react';
import './LocationController.css';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../scripts/Translator';
import BackIcon from '../../../../images/back.png';
import AddIcon from '../../../../images/add.png';
import LocationDetail from './LocationDetail';
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import ListViewItem from '../../../../components/list-view/ListViewItem';
import Api from '../../../scripts/Api';


class LocationController extends Component {

    constructor(props) {
        super(props);
        this.state = {view: "menu"};
    }

    componentDidMount() {
        this.loadLocations(60 * 10);
    }

    loadLocations(cache) {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.get("/locations", body => {
            _this.locationElements = body.map((loc, i) => <ListViewItem key={i} text={loc.name} disclosureIndicator onClick={this.onLocationClick.bind(this, loc)}/>);
            _this.setState({loading: false});
        }, error => {
            _this.setState({error: error.error, loading: false});
        }, cache);
    }

    onLocationClick(loc) {
        this.selectedLocation = loc;
        this.setState({view: "detail"});
    }

    onAddClick() {
        this.selectedLocation = {name: Translator.get("new_location")};
        this.setState({view: "detail"});
    }

    onBackClick() {
        this.props.onBack();
    }

    onChildBack() {
        this.setState({view: "menu"});
    }

    onLocationAdded() {
        this.loadLocations(0);
        this.setState({view: "menu"});
    }

    render() {
        if (this.state.view === "menu") {
            return (
                <div className="location-controller">
                    <NavigationBar title={Translator.get("locations_title")} buttonsLeft={
                    <img src={BackIcon} alt="Back" onClick={this.onBackClick.bind(this)}></img>
                    } buttonsRight={
                        <img src={AddIcon} alt="Add Role" onClick={this.onAddClick.bind(this)}></img>
                    }/>
                    {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                    <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                    <div className="location-controller-list-view">
                        {this.locationElements}
                    </div>
                </div>            
            );
        }
        if (this.state.view === "detail") {
            return <LocationDetail location={this.selectedLocation} onBack={this.onChildBack.bind(this)} onLocationAdd={this.onLocationAdded.bind(this)}></LocationDetail>
        }
    }
}

export default LocationController;