import React, { Component } from 'react';
import './Textarea.css';

class Textarea extends Component {

    render() {
        let propsClass = this.props.className || "";
        let appearance = this.props.appearance || "standard";
        let errorClass = this.props.error != null ? "error" : "";
        return (
            <div className={"textarea-wrapper " + appearance + " " + propsClass + " " + errorClass}>
                <textarea className={"textarea "} 
                    placeholder={this.props.placeholder || " "} 
                    value={this.props.value} 
                    onChange={this.props.onChange}
                    type={this.props.type}
                    row={this.props.row}>
                </textarea>
                <div className="textarea-label">{this.props.label}</div>
            </div>
        );
    }
}

export default Textarea;