import React, { Component } from 'react';
import './FolderDetail.css'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import Translator from '../../../scripts/Translator';
import Checkbox from '../../../../components/checkbox/Checkbox';
import Api from '../../../scripts/Api';
import ListViewItem from '../../../../components/list-view/ListViewItem';
import FilesController from './FilesController';
import Input from '../../../../components/input/Input';

class FolderDetail extends Component {

    constructor(props) {
        super(props);
        if (!this.props.folder) {
            this.state = {
                name: "",
                public: false
            };
        }
        else {
            this.state = {
                id: props.folder.id,
                name: props.folder.name,
                public: props.folder.public
            };
        }
        this.state.view = "detail";
    }

    onCancel() {
        this.props.onBack();
    }

    onSave() {
        this.setState({loading: true});
        var body = new FormData();
        body.append("name", this.state.name);
        body.append("public", this.state.public);
        if (this.state.id) {
            Api.putForm("/files/"+this.state.id, body, () => {
                this.setState({loading: false});
                this.props.onFolderAdded();
            }, e => {
                this.setState({loading: false, error: e.error});
            });
        }
        else {
            Api.postForm("/files", body, () => {
                this.setState({loading: false});
                this.props.onFolderAdded();
            }, e => {
                this.setState({loading: false, error: e.error});
            });
        }
    }

    onDelete() {
        if (this.state.id) {
            Api.delete("/files/"+this.state.id, {}, () => {
                this.setState({loading: false});
                this.props.onFolderAdded();
            }, e => {
                this.setState({loading: false, error: e.error});
            });
        }
    }

    onValueChanged(key, e) {
        var o = {};
        o[key] = e.target.value;
        this.setState(o);
    }

    onPublicChange() {
        this.setState({public: !this.state.public});
    }

    onFilesClicked() {
        this.setState({view: "files"});
    }

    onChildBack() {
        this.setState({view: "detail"});
    }

    render() {
        if (this.state.view === "files") {
            return <FilesController onBack={this.onChildBack.bind(this)} folderId={this.state.id}/>
        }
        return (
            <div className="folder-detail">
                <NavigationBar title={Translator.get("folder_detail_title")} buttonsLeft={
                    <div onClick={this.onCancel.bind(this)}>{Translator.get("cancel")}</div>
                } buttonsRight={
                    <div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>
                }/>
                {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="folder-detail-content">
                    <Input type="text" className="folder-detail-input" value={this.state.name} 
                        onChange={this.onValueChanged.bind(this, "name")}
                        label={Translator.get("name")}>
                    </Input>
                    <div className="folder-detail-checkbox-wrapper">
                        <Checkbox className="folder-detail-checkbox" checked={this.state.public} onCheck={this.onPublicChange.bind(this)}/>
                        <span className="folder-detail-checkbox-text">{Translator.get("display_on_webpage")}</span>
                    </div>
                    {this.state.id && <div className="folder-detail-file-view-wrapper">
                        <ListViewItem text={Translator.get("folder_view_files")} disclosureIndicator onClick={this.onFilesClicked.bind(this)}/>
                    </div>}
                    {this.state.id && 
                        <div onClick={this.onDelete.bind(this)} className="folder-detail-remove">{Translator.get("delete")}</div>}
                </div>
            </div>
        );
    }

}

export default FolderDetail;