import React, { Component } from 'react';
import './TabController.css';
import TabItem from './TabItem';

class TabController extends Component {

    constructor(props) {
        super(props);
        this.state = {selectedTab: null};
    }

    componentDidMount() {
        this.selectTab(this.props.default);
    }

    onTabSelected(tab) {
        this.selectTab(tab.props.name);
    }

    selectTab(name) {
        var selectedTab = this.props.children.filter(c => c.props.name && c.props.name === name);
        this.tabs = this.props.tabs.map((tabObj) =>
            {
                return <TabItem key={tabObj.key} name={tabObj.key} title={tabObj.title} icon={tabObj.icon} iconInactive={tabObj.iconInactive} 
                    onTabSelected={this.onTabSelected.bind(this)} selected={tabObj.key===name}/>;
            }
        );
        this.setState({selectedTab: selectedTab});
    }

    render() {
        return (
            <div className="tab-controller">
                <div className="tab-controller-content">
                    {this.state.selectedTab}
                </div>
                <div className="tab-controller-tabbar">
                    {this.tabs}
                </div>
            </div>
        );
    }
}

export default TabController;