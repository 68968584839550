import React, { Component } from 'react';
import './MemberRequestController.css'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar.js'
import Translator from '../../../scripts/Translator';
import ListViewItem from '../../../../components/list-view/ListViewItem';
import BackIcon from '../../../../images/back.png';
import MemberRequestDetail from './MemberRequestDetail';

class MemberRequestController extends Component {

    constructor() {
        super();
        this.state = {
            selectedMember: null
        };
    }

    onMemberSelect(member) {
        this.setState({selectedMember: member});
    }

    onChildBack() {
        this.setState({selectedMember: null});
    }

    onBack() {
        this.props.onBack();
    }

    onMemberRequestsChanged() {
        this.props.onMemberRequestChanged();
    }

    render() {
        if (this.state.selectedMember) {
            return <MemberRequestDetail member={this.state.selectedMember} onBack={this.onChildBack.bind(this)}
            onMemberRequestChanged={this.onMemberRequestsChanged.bind(this)}>
            </MemberRequestDetail>;
        }
        return (
            <div className="member-request-controller">
                <NavigationBar title={Translator.get("member_requests_title")} buttonsLeft={
                    <img src={BackIcon} alt="Back" onClick={this.onBack.bind(this)}></img>}/>
                <div className="member-request-controller-content">
                    {this.props.requests && this.props.requests.map((r, i) => <div className="member-request-controller-request" key={i}>
                        <ListViewItem 
                            text={r.member.first_name + " " + r.member.last_name} 
                            disclosureIndicator 
                            onClick={this.onMemberSelect.bind(this, r.member)}>
                        </ListViewItem>
                    </div>)}
                </div>
            </div>
            
        );
    }
}

export default MemberRequestController;