import React, { Component } from 'react';
import './TrainingOverview.css'
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../scripts/Translator';

import AddIcon from '../../../../images/add.png'
import BackIcon from '../../../../images/back.png';
import Api from '../../../scripts/Api';
import ListViewItem from '../../../../components/list-view/ListViewItem';
import TrainingDetail from './TrainingDetail';

class TrainingOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            view: "overview"
        };
        this.canEdit = props.canEdit;
    }

    componentDidMount() {
        this.loadTrainings(60 * 10);
    }

    loadTrainings(cache) {
        this.setState({loading: true, error: null});
        var _this = this;
        var weekdays = [Translator.get("monday"), Translator.get("tuesday"), Translator.get("wednsday"), Translator.get("thursday"), Translator.get("friday"), 
            Translator.get("saturday"), Translator.get("sunday")];
        Api.get("/teams/"+this.props.id+"/trainings", body => {    
            _this.trainingsCells = body.map((t, i) => <ListViewItem key={i} text={weekdays[t.weekday]+", "+t.start_time+"-"+t.end_time} disclosureIndicator onClick={this.onEditTrainingClick.bind(this, t)}/>);
            _this.setState({loading: false});
        }, error => {
            _this.setState({error: error.error, loading: false});
        }, cache);
    }

    onEditTrainingClick(t) {
        this.selectedTraining = t;
        this.setState({view: "view-training"});
    }

    onAddClick() {
        this.selectedTraining = null;
        this.setState({view: "view-training"});
    }

    onBackClick() {
        this.props.onBack();
    }

    onChildBack() {
        this.setState({view: "overview"});
    }

    onMemberResign(mem_id, rel_id) {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.delete("/teams/"+this.props.id+"/coaches/"+mem_id, {}, () => {
            _this.setState({view: "overview", loading: false});
            _this.loadTrainings(0);
        }, error => {
            _this.setState({error: error.error, loading: false});
        });
    }

    onTrainingSaved() {
        this.loadTrainings(0);
        this.setState({view: "overview"});
    }

    render() {
        if (this.state.view === "view-training") {
            return <TrainingDetail teamID={this.props.id} onBack={this.onChildBack.bind(this)} canEdit={this.canEdit}
                onTrainingSaved={this.onTrainingSaved.bind(this)} training={this.selectedTraining}></TrainingDetail>
        }
        var content;
        if (this.state.loading) {
            content = (<Popup><ActivityIndicator/></Popup>);
        }
        return (
            <div className="training-overview">
                <NavigationBar title={this.props.teamName} buttonsRight={
                    this.canEdit && <img src={AddIcon} alt="Add Training Button" onClick={this.onAddClick.bind(this)}></img>
                } buttonsLeft={
                    <img src={BackIcon} alt="Back" onClick={this.onBackClick.bind(this)}></img>
                }/>
                {content}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="training-overview-content">
                    {this.trainingsCells}
                </div>
            </div>
        );
    }
}

export default TrainingOverview;