import React, { Component } from 'react';
import './SecurityController.css';

import NavigationBar from '../../../components/navigation-bar/NavigationBar.js'
import RolesController from './roles/RolesController.js'

import ListViewItem from '../../../components/list-view/ListViewItem.js'
import Translator from '../../scripts/Translator';
import UsersController from './users/UsersController';


class SecurityController extends Component {

    constructor(props) {
        super(props);
        this.state = {view: "menu"};
    }

    onRolesClick() {
        this.setState({view: "roles"});
    }

    onUsersClick() {
        this.setState({view: "users"});
    }

    onBack() {
        this.setState({view: "menu"});
    }

    render() {
        if (this.state.view === "menu") {
            return (
                <div>
                    <NavigationBar title={Translator.get("security_title")}/>
                    <div className="security-controller-list-view">
                        {this.props.context.user.role.access_control.edit_roles && 
                            <ListViewItem onClick={this.onRolesClick.bind(this)} text={Translator.get("roles_title")} disclosureIndicator/>}
                        {this.props.context.user.role.access_control.edit_users && 
                            <ListViewItem onClick={this.onUsersClick.bind(this)} text={Translator.get("users_title")} disclosureIndicator/>}
                    </div>
                </div>            
            );
        }
        else if (this.state.view === "roles") {
            return (<RolesController onBack={this.onBack.bind(this)}/>);
        }
        else if (this.state.view === "users") {
            return (<UsersController onBack={this.onBack.bind(this)}/>);
        }
    }
}

export default SecurityController;