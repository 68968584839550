import React, { Component } from 'react';
import './EventDetail.css'
import NavigationBar from '../../../components/navigation-bar/NavigationBar';
import BackIcon from '../../../images/back.png';
import Translator from '../../scripts/Translator';

import AcceptIcon from '../../../images/event-accept.png';
import AcceptIconInactive from '../../../images/event-accept-inactive.png';
import DeclineIcon from '../../../images/event-decline.png';
import DeclineIconInactive from '../../../images/event-decline-inactive.png';
import Api from '../../scripts/Api';
import Popup from '../../../components/popup/Popup';
import ActivityIndicator from '../../../components/activity-indicator/ActivityIndicator';
import EventAskReasonPopup from './EventAskReasonPopup';
import EventEdit from './EventEdit';
import EventDetailPopup from './EventDetailPopup';

class EventDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answer: props.event.answer,
            askingReason: false,
            view: "detail"
        };
        this.canEdit = props.context.user.role.access_control.edit_all_events || props.event.author === props.context.user.username;
        this.ac = this.props.context.user.role.access_control;
    }

    componentDidMount() {
        this.setState({loading: true, error: null});
        var toBeLoaded = 2;
        Api.get("/profile", p => {
            this.memberID = p.member.id;
            toBeLoaded--;
            if (toBeLoaded === 0) {
                this.setState({loading: false});
            }
        }, e => {
            this.setState({loading: false, error: e.error});
        }, 600);
        Api.get("/events/"+this.props.event.id+"/answers", res => {
            this.accepted = [];
            this.denied = [];
            this.unanswered = [];
            for (var i = 0; i < res.length; i++) {
                const name = res[i].first_name + " " + res[i].last_name;
                const answerObject = {answer: res[i].answer, comment: res[i].comment};
                if (res[i].answer === -1) {
                    this.unanswered.push(<div key={i} className="event-detail-member" onClick={this.onAnswerClicked.bind(this, this.props.event, answerObject, name, res[i].member_id)}>
                        <img alt="" src={DeclineIconInactive}></img>
                        <span>{res[i].first_name+" "+res[i].last_name}</span>
                    </div>);
                }
                if (res[i].answer === 0) {
                    this.denied.push(<div key={i} className="event-detail-member" onClick={this.onAnswerClicked.bind(this, this.props.event, answerObject, name, res[i].member_id)}>
                        <img alt="" src={DeclineIcon}></img>
                        <span>{res[i].first_name+" "+res[i].last_name}</span>
                        <div className="event-detail-member-reason">{res[i].comment}</div>
                    </div>);
                }
                if (res[i].answer === 1) {
                    this.accepted.push(<div key={i} className="event-detail-member" onClick={this.onAnswerClicked.bind(this, this.props.event, answerObject, name, res[i].member_id)}>
                        <img alt="" src={AcceptIcon}></img>
                        <span>{res[i].first_name+" "+res[i].last_name}</span>
                    </div>);
                }
            }
            toBeLoaded--;
            if (toBeLoaded === 0) {
                this.setState({loading: false});
            }
        }, e => {
            this.setState({error: e.error, loading: false});
        }, 600);
        Api.get("/profile", res => {
            this.profile = res;
        }, e => {}, 600);
    }

    onBack() {
        this.props.onBack();
    }

    acceptInvitation() {
       this.sendAnswer(1, "");
    }

    declineInvitation(reason) {
        this.setState({askingReason: false});
        this.sendAnswer(0, reason);
    }

    sendAnswer(answer, comment) {
        this.setState({loading: true, error: null});
        Api.put("/events/"+this.props.event.id+"/answers/"+this.memberID, {answer: answer, comment: comment}, () => {
            this.setState({loading: false, answer: answer});
            this.props.onEventSaved();
        }, e => {
            this.setState({loading: false, error: e.error});
        });
        Api.invalidateCache("/events/"+this.props.event.id+"/answers");
    }

    onEdit() {
        this.setState({view: "edit"});
    }

    onChildBack() {
        this.setState({view: "detail"});
    }

    onEventEdited() {
        this.props.onEventSaved();
    }

    onAnswerClicked(event, answer, name, memberId) {
        if (!answer) return;
        if (!this.ac.edit_related_answers && !this.ac.edit_all_answers && memberId !== this.profile.member.id) {
            return;
        }
        this.answerClickEvent = event;
        this.answerClicked = answer;
        this.answerMember = {name: name, id: memberId};
        this.setState({answerDetail: true});
    }

    onEventAdded() {
        this.setState({answerDetail: false});
        this.componentDidMount();
    }

    render() {
        if (this.state.view === "edit") {
            return <EventEdit onBack={this.onChildBack.bind(this)} event={this.props.event} onEventEdited={this.onEventEdited.bind(this)}></EventEdit>
        }
        var splittedDate = this.props.event.date.split("-");
        var splittedTime = this.props.event.start_time.split(":");
        var deadline = new Date(this.props.event.date);
        deadline.setHours(splittedTime[0], splittedTime[1]);
        deadline.setHours(deadline.getHours() - this.props.event.deadline);
        return <div className="event-detail">
            <NavigationBar title={Translator.get("events_title")} buttonsLeft={
                <img src={BackIcon} alt="Back" onClick={this.onBack.bind(this)}></img>
            } buttonsRight={
                this.canEdit && <div onClick={this.onEdit.bind(this)}>{Translator.get("edit")}</div>
            }/>
            <div className="event-detail-content">
                {this.state.answerDetail &&   
                    <EventDetailPopup event={this.answerClickEvent} answer={this.answerClicked} member={this.answerMember} onEventSaved={this.onEventAdded.bind(this)}>
                    </EventDetailPopup>
                }
                {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                {this.state.askingReason && 
                    <EventAskReasonPopup onSendReason={this.declineInvitation.bind(this)} onCancel={() => {this.setState({askingReason: false})}}>
                    </EventAskReasonPopup>
                }
                <h3 className="event-detail-title">
                    {this.props.event.title}
                </h3>
                <div className="event-detail-label">{Translator.get("location")}</div>
                <div className="event-detail-value">
                    {this.props.event.location}
                </div>
                {this.props.event.address!=="" && 
                    <div>
                        <div className="event-detail-label">{Translator.get("address")}</div>
                        <div className="event-detail-value">
                            {this.props.event.address}
                        </div>
                    </div>
                }
                <div className="event-detail-label">{Translator.get("date")}</div>
                <div className="event-detail-value">
                    {splittedDate[2] + "." + splittedDate[1] + "." + splittedDate[0]}
                </div>
                <div className="event-detail-label">{Translator.get("time")}</div>
                <div className="event-detail-value">
                    {this.props.event.start_time + "-" + this.props.event.end_time}
                </div>
                {this.props.event.description!=="" && 
                    <div>
                        <div className="event-detail-label">{Translator.get("description")}</div>
                        <div className="event-detail-value">
                            {this.props.event.description}
                        </div>
                    </div>
                }
                <div className="event-detail-label">{Translator.get("deadline")}</div>
                <div className="event-detail-value">
                    {deadline.toLocaleString()}
                </div>
                {this.props.event.is_invited && <div className="event-detail-answer">
                    {this.state.answer!==1 && <img src={AcceptIconInactive} alt="Accept Invitation" onClick={this.acceptInvitation.bind(this)}></img>}
                    {this.state.answer===1 && <img src={AcceptIcon} alt="Accept Invitation"></img>}
                    <div></div>
                    {this.state.answer!==0 && <img src={DeclineIconInactive} alt="Decline Invitation" onClick={() => {this.setState({askingReason: true})}}></img>}                    
                    {this.state.answer===0 && <img src={DeclineIcon} alt="Decline Invitation"></img>}
                </div>}
                <div className="event-detail-accepted">{this.accepted}</div>
                <div className="event-detail-denied">{this.denied}</div>
                <div className="event-detail-unanswerded">{this.unanswered}</div>
            </div>
        </div>;
    }

}

export default EventDetail;