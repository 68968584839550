import React, { Component } from 'react';
import './App.css';
import RootController from './root-controller/RootController.js';
import Login from './login/Login.js';
import Api from './scripts/Api';
import Translator from './scripts/Translator';
import Popup from '../components/popup/Popup';
import ActivityIndicator from '../components/activity-indicator/ActivityIndicator';
import UserGuideController from './user-guide/UserGuideController';
import Router from './scripts/Router';
import MemberRequest from './member-request/MemberRequest';
import UserVerify from './user-verify/UserVerify';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accessToken: "",
            context: {},
            loading: true,
            help: false,
            route: Router.getPath(0)
        };
        //Api.setBaseUrl("http://localhost:8080");
        Api.setBaseUrl("https://api.volleyhuenenberg.ch");
        Api.setExpiredCallback((err) => {this.setState({accessToken: "", error: err})});
        Translator.loadLanguage("de");
        this.version = "v.1.6.2";
    }

    componentDidMount() {
        var _this = this;
        var intervallID = setInterval(() => {
            if (Translator.isLoaded()) {
                _this.setState({ loading: false });
                clearInterval(intervallID);
            }
        }, 500);
        this.listenerID = Router.addChangeListener(() => {_this.setState({route: Router.getPath(0)})});
    }

    componentWillUnmount() {
        Router.removeChangeListener(this.listenerID);
    }

    onAuthentication(token) {
        Api.setAccessToken(token.Token);
        this.setState({ context: { user: token.Owner }, accessToken: token });
        Router.setPath("app", 0);
    }

    onShowHelp() {
        Router.setPath("help", 0);
    }

    render() {
        if (this.state.route === "member-request" || this.state.route === "beitritt") {
            return <MemberRequest></MemberRequest>;
        }
        if (this.state.route === "help") {
            return <UserGuideController></UserGuideController>;
        }
        if (this.state.route === "verify") {
            return <UserVerify></UserVerify>
        }
        if (this.state.accessToken !== "") {
            return (
                <div className="clubman-root">
                    {this.state.loading && <Popup><ActivityIndicator /></Popup>}
                    <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                    <RootController context={this.state.context} />
                </div>
            );
        }
        return (
            <div className="clubman-root">
                {this.state.loading && <Popup><ActivityIndicator /></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <Login version={this.version} onAuthentication={this.onAuthentication.bind(this)} showHelp={this.onShowHelp.bind(this)} />
            </div>
        );
    }
}

export default App;
