import React, { Component } from 'react';
import './NavigationBar.css';

class NavigationBar extends Component {

    render() {
        return (
            <div className={"navigation-bar " + (this.props.className ||"")}>
                <div className="navigation-bar-buttons-left">{this.props.buttonsLeft}</div>
                <div className="navigation-bar-title">{this.props.title}</div>
                <div className="navigation-bar-buttons-right">{this.props.buttonsRight}</div>
            </div>
        );
    }
}

export default NavigationBar;