import React, { Component } from 'react';
import './EventNewCustom.css'
import NavigationBar from '../../../components/navigation-bar/NavigationBar';
import Translator from '../../scripts/Translator';
import Api from '../../scripts/Api';
import Popup from '../../../components/popup/Popup';
import ActivityIndicator from '../../../components/activity-indicator/ActivityIndicator';
import Checkbox from '../../../components/checkbox/Checkbox';
import Input from '../../../components/input/Input';
import Dropdown from '../../../components/dropdown/Dropdown';
import Textarea from '../../../components/textarea/Textarea';

class EventNewCustom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            location: "",
            event_address: "",
            repeatable_index: "0",
            weekday_index: "0",
            start_time: "",
            end_time: "",
            deadline_days: "0",
            deadline_hours: "0",
            description: "",
            date: "",
            repeat_end: "",
            invalids: {}
        };
    }

    componentDidMount() {
        this.setState({loading: true});
        Api.get("/teams", res => {
            this.teams = res;
            var teamsChecked = {};
            for (var i = 0; i < res.length; i++) {
                teamsChecked[res[i].id] = false;
            }
            this.setState({loading: false, selectedTeams: teamsChecked});
        }, e => {
            this.setState({loading: false, error: e.error});
        }, 600);
    }

    onValueChanged(key, e) {
        var s = {};
        s[key] = e.target.value;
        this.setState(s);
    }

    onTeamCheck(id) {
        var s = this.state.selectedTeams;
        s[id] = !s[id];
        this.setState({selectedTeams: s});
    }

    onCancel() {
        this.props.onBack();
    }

    onSave() {
        var invalids = {
            title: false,
            location: false,
            start_time: false,
            end_time: false
        };
        var hasError = false;
        if (this.state.title === "") {
            invalids.title = true;
            hasError = true;
        }
        if (this.state.location === "") {
            invalids.location = true;
            hasError = true;
        }
        if (this.state.start_time === "") {
            invalids.start_time = true;
            hasError = true;
        }
        if (this.state.end_time === "") {
            invalids.end_time = true;
            hasError = true;
        }
        if (hasError) {
            this.setState({invalids: invalids});
            return;
        }
        var teamIds = [];
        for (var id in this.state.selectedTeams) {
            if (this.state.selectedTeams[id]) {
                teamIds.push(id);
            }
        }
        var queue = [];
        var startDate = new Date();
        var endDate = new Date(this.state.repeat_end);
        endDate.setDate(endDate.getDate() + 1);
        if (this.state.repeatable_index === "0") {
            startDate = new Date(this.state.date);
            endDate = new Date(this.state.date);
            
        }
        while (endDate - startDate >= 0) {
            if (this.state.repeatable_index === "1" && (startDate.getDay()-1)%7 !== Number(this.state.weekday_index)) {
                startDate.setDate(startDate.getDate() + 1);
                continue;
            }
            var event = {
                title: this.state.title,
                location: this.state.location,
                address: this.state.event_address,
                date: startDate.toISOString().substring(0, 10),
                start_time: this.state.start_time,
                end_time: this.state.end_time,
                deadline: Number(this.state.deadline_days)*24 + Number(this.state.deadline_hours),
                description: this.state.description,
                team_ids: teamIds
            };
            startDate.setDate(startDate.getDate() + 7);
            queue.push(event);
        }
        this.setState({loading: true});
        this.postEvents(queue, 0);
    }

    postEvents(events, index) {
        if (index >= events.length) {
            this.setState({loading: false});
            this.props.onEventAdded();
            return;
        }
        Api.post("/events", events[index], () => {
            this.postEvents(events, index+1);
        }, e => {
            console.log(e);
            this.setState({error: e.error, loading: false});
        });
    }

    render() {
        var dayItems = [<option key={0} value={0}>{"0 " + Translator.get("days")}</option>, <option key={1} value={1}>{"1 " + Translator.get("day")}</option>];
        for (var i = 2; i <= 30; i++) {
            dayItems.push(<option key={i} value={i}>{i + " " + Translator.get("days")}</option>);
        }
        var hourItems = [<option key={0} value={0}>{"0 " + Translator.get("hours")}</option>, <option key={1} value={1}>{"1 " + Translator.get("hour")}</option>];
        for (i = 2; i <= 30; i++) {
            hourItems.push(<option key={i} value={i}>{i + " " + Translator.get("hours")}</option>);
        }
        return <div className="event-new-custom">
            <NavigationBar title={Translator.get("new_event")} buttonsLeft={
                <div onClick={this.onCancel.bind(this)}>{Translator.get("cancel")}</div>
            }buttonsRight={
                <div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>
            }/>
            <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
            {this.state.loading && <Popup><ActivityIndicator/></Popup>}
            <div className="event-new-custom-content">
                <Input type="text" className="event-new-custom-input" 
                    value={this.state.title} 
                    onChange={this.onValueChanged.bind(this, "title")}
                    label={Translator.get("title")}
                    error={this.state.invalids.title ? "" : null}>
                </Input>
                <Input type="text" className="event-new-custom-input"
                    value={this.state.location} 
                    onChange={this.onValueChanged.bind(this, "location")}
                    label={Translator.get("location")}
                    error={this.state.invalids.location ? "" : null}>
                </Input>
                <Input type="text" className="event-new-custom-input" 
                    value={this.state.event_address} 
                    onChange={this.onValueChanged.bind(this, "event_address")}
                    label={Translator.get("event_address")}>
                </Input>
                <Dropdown className="event-new-custom-input" value={this.state.repeatable_index} 
                    onChange={this.onValueChanged.bind(this, "repeatable_index")}
                    label={Translator.get("event_repeatable_title")}>
                    <option value={0}>{Translator.get("event_single")}</option>
                    <option value={1}>{Translator.get("event_repeatable")}</option>
                </Dropdown>
                {this.state.repeatable_index==="1" && 
                    <div>
                        <Dropdown className="event-new-custom-input" value={this.state.weekday_index} 
                            onChange={this.onValueChanged.bind(this, "weekday_index")}
                            label={Translator.get("weekday")}>
                            <option value={0}>{Translator.get("monday")}</option>
                            <option value={1}>{Translator.get("tuesday")}</option>
                            <option value={2}>{Translator.get("wednsday")}</option>
                            <option value={3}>{Translator.get("thursday")}</option>
                            <option value={4}>{Translator.get("friday")}</option>
                            <option value={5}>{Translator.get("saturday")}</option>
                            <option value={6}>{Translator.get("sunday")}</option>
                        </Dropdown>
                        <Input type="date" className="event-new-custom-input" value={this.state.repeat_end} 
                            onChange={this.onValueChanged.bind(this, "repeat_end")}
                            label={Translator.get("event_repeat_until")}>
                        </Input>
                    </div>
                }
                {this.state.repeatable_index==="0" && 
                    <div>
                        <Input type="date" className="event-new-custom-input" value={this.state.date} 
                            onChange={this.onValueChanged.bind(this, "date")}
                            label={Translator.get("date")}>
                        </Input>
                    </div>
                }
                <Input type="time" className="event-new-custom-input" value={this.state.start_time} 
                    onChange={this.onValueChanged.bind(this, "start_time")}
                    label={Translator.get("start_time")}
                    error={this.state.invalids.start_time ? "" : null}>
                </Input>
                <Input type="time" className="event-new-custom-input" value={this.state.end_time} 
                    onChange={this.onValueChanged.bind(this, "end_time")}
                    label={Translator.get("end_time")}
                    error={this.state.invalids.end_time ? "" : null}>
                </Input>
                <div className="event-new-custom-deadline">
                    <Dropdown className="event-new-custom-input" value={this.state.deadline_days} 
                        onChange={this.onValueChanged.bind(this, "deadline_days")}
                        label={Translator.get("event_deadline")}>
                        {dayItems}
                    </Dropdown>
                    <Dropdown className="event-new-custom-input" value={this.state.deadline_hours} 
                        onChange={this.onValueChanged.bind(this, "deadline_hours")}>
                        {hourItems}
                    </Dropdown>
                    <span>{Translator.get("event_deadline_text")}</span>
                </div>
                <Textarea className="event-new-custom-input" value={this.state.description} 
                    onChange={this.onValueChanged.bind(this, "description")} 
                    rows="2"
                    label={Translator.get("event_description")}>
                </Textarea>
                <h3 className="event-new-teams-title">{Translator.get("event_teams_title")}</h3>
                {this.teams && this.teams.map((t, i) => 
                    <div className="event-new-custom-team-row" key={i}>
                        <Checkbox checked={this.state.selectedTeams[t.id]} onCheck={this.onTeamCheck.bind(this, t.id)}></Checkbox>
                        <div className="event-new-custom-team-row-name">{t.name}</div>                
                    </div>)
                }
            </div>
        </div>;
    }

}

export default EventNewCustom;