import React, { Component } from 'react';
import './Notification.css'
import Translator from '../../../scripts/Translator';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Checkbox from '../../../../components/checkbox/Checkbox';
import Api from '../../../scripts/Api';

class Notification extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.selectedNc = {};
        this.notificationControl = this.props.user.notification_control;
        this.canReceiveAdmin = props.context.user.role.access_control.receive_admin_notifications;
        for (var nc in this.notificationControl.admin) {
            this.selectedNc[nc] = this.notificationControl.admin[nc];
        }
        for (nc in this.notificationControl.common) {
            this.selectedNc[nc] = this.notificationControl.common[nc];
        }
    }

    componentDidMount() {
        this.createCheckboxes();
    }

    onCheckBox(key) {
        this.selectedNc[key] = !this.selectedNc[key];
        this.createCheckboxes();
    }

    onSave() {
        this.setState({loading: true, error: null});
        var _this = this;
        var body = {
            admin: {
                user_added: this.selectedNc.user_added
            }
        };
        Api.put("/profile/notification", body, () => {
            _this.setState({loading: false});
            _this.props.onSaved();
        }, error => {
            _this.setState({loading: false, error: error.error});
        });
        
    }

    onCancel() {
        this.props.onBack();
    }

    createCheckboxes() {
        var ncElements = [];
        if (this.canReceiveAdmin) {
            for (var key in this.notificationControl.admin) {
                ncElements.push(
                    <div className="notification-nc-row" key={key}>
                        <div className="notification-nc-title">{Translator.get(key)}</div>
                        <Checkbox name={key} checked={this.selectedNc[key]} onCheck={this.onCheckBox.bind(this)}/> 
                    </div>
                );
            }
        }        
        for (key in this.notificationControl.common) {
            ncElements.push(
                <div className="notification-nc-row" key={key}>
                    <div className="notification-nc-title">{Translator.get(key)}</div>
                    <Checkbox name={key} checked={this.selectedNc[key]} onCheck={this.onCheckBox.bind(this)}/> 
                </div>
            );
        }
        this.setState({ncElements: ncElements});
    }

    render() {
        return (
            <div className="notification">
                <NavigationBar title={Translator.get("notification_title")} buttonsRight={
                    <div onClick={this.onCancel.bind(this)}>{Translator.get("cancel")}</div>
                } buttonsLeft={
                    <div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>
                }/>
                <div className="notification-content">
                    {this.state.ncElements}
                </div>
            </div>
        );
    }

}

export default Notification;