import React, { Component } from 'react';
import './UserGuideController.css'

import MenuIcon from '../../images/menu-icon.png'
import VolleyLogo from '../../images/volley-logo.png'
import UserGuideRegistration from './UserGuideRegistration';
import UserGuideInstallation from './UserGuideInstallation';
import Router from '../scripts/Router';
import UserGuideEditTeam from './UserGuideEditTeam';
import UserGuideAddPlayer from './UserGuideAddPlayer';
import UserGuideAddCoach from './UserGuideAddCoach';
import UserGuideAddTraining from './UserGuideAddTraining';

class UserGuideController extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: "open"
        };
        var _this = this;
        window.addEventListener("resize", () => _this.setupNavigation());
        this.menuItems = [
            <div className="user-guide-navigation-item" onClick={this.navigate.bind(this, "installation")} key="installation">Installation</div>,
            <div className="user-guide-navigation-item" onClick={this.navigate.bind(this, "registration")} key="registration">Registrierung</div>,
            <div className="user-guide-navigation-item" onClick={this.navigate.bind(this, "edit_team")} key="edit_team">Team bearbeiten</div>,
            <div className="user-guide-navigation-item" onClick={this.navigate.bind(this, "add_player")} key="add_player">Spieler erfassen</div>,
            <div className="user-guide-navigation-item" onClick={this.navigate.bind(this, "add_coach")} key="add_coach">Trainer erfassen</div>,
            <div className="user-guide-navigation-item" onClick={this.navigate.bind(this, "add_training")} key="add_training">Training erfassen</div>,            
            <div className="user-guide-navigation-item" onClick={this.backToApp.bind(this)} key="backToApp">Zurück zur App</div>
        ];
        this.userGuides = {
            installation: <UserGuideInstallation></UserGuideInstallation>,
            registration: <UserGuideRegistration></UserGuideRegistration>,
            edit_team: <UserGuideEditTeam navigate={this.navigate.bind(this)}></UserGuideEditTeam>,
            add_player: <UserGuideAddPlayer navigate={this.navigate.bind(this)}></UserGuideAddPlayer>,
            add_coach: <UserGuideAddCoach></UserGuideAddCoach>,
            add_training: <UserGuideAddTraining></UserGuideAddTraining>
        };
        if (!Router.getPath(1)) {
            Router.setPath("installation", 1);
        }
    }

    componentDidMount() {
        this.setupNavigation();
        this.listenerID = Router.addChangeListener(() => {
            this.setState({currentGuide: Router.getPath(1)});
        });
    }

    componentWillUnmount() {
        Router.removeChangeListener(this.listenerID);
    }

    navigate(uri) {
        this.toggleNavigation();
        Router.setPath(uri, 1);
    }

    backToApp() {
        Router.setPath("app", 0)
    }

    setupNavigation() {
        var width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        if (width < 1000) {
            this.setState({navigation: "closed", mobile: true});
        }
        else {
            this.setState({navigation: "open", mobile: false});
        }
    }

    toggleNavigation() {
        if (!this.state.mobile) return;
        if (this.state.navigation === "open") {
            this.setState({navigation: "closed"});
        }
        else {
            this.setState({navigation: "open"});
        }
    }

    render() {
        var contentClass = "";
        if (this.state.mobile) contentClass = " mobile";
        return <div className="user-guide-root">
            <div className="user-guide-header">
                {this.state.mobile && <img alt="menu-button" className="user-guide-header-menu-icon" src={MenuIcon} onClick={this.toggleNavigation.bind(this)}/>}
                <img alt="" className="user-guide-logo" src={VolleyLogo}/>
            </div>
            <div className={"user-guide-navigation "+this.state.navigation}>
                {this.menuItems}
            </div>
            {this.state.mobile && <div className={"user-guide-navigation-backdrop " + this.state.navigation}></div>}
            <div className={"user-guide-content"+contentClass}>
                {this.userGuides[this.state.currentGuide]}
            </div>
        </div>;
    }
}

export default UserGuideController;