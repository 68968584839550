import React, { Component } from 'react';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import Checkbox from '../../../../components/checkbox/Checkbox';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Popup from '../../../../components/popup/Popup';
import Api from '../../../scripts/Api';
import Translator from '../../../scripts/Translator';
import './RoleDetail.css';
import Input from '../../../../components/input/Input';

class RoleDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            acElements: [],
            roleName: props.role.name
        };
        this.selectedAc = {};
        for (var ac in props.role.access_control) {
            this.selectedAc[ac] = props.role.access_control[ac];
        }
    }

    componentDidMount() {
        this.createCheckboxes();
    }

    onCheckBox(key) {
        this.selectedAc[key] = !this.selectedAc[key];
        this.createCheckboxes();
    }

    onNameChange(e) {
        this.setState({roleName: e.target.value});
    }

    onCancel() {
        this.props.onBack();
    }

    onSave() {
        var body = {
            name: this.state.roleName,
            access_control: this.selectedAc,
            id: this.props.role.id
        };
        this.setState({loading: true, error: null});
        var _this = this;
        if (this.props.role.id) {
            Api.put("/roles/"+this.props.role.id, body, () => {
                _this.setState({loading: false});
                _this.props.onRoleAdd();
            }, error => {
                _this.setState({loading: false, error: error.error});
            });
        }
        else {
            Api.post("/roles", body, () => {
                _this.setState({loading: false});
                _this.props.onRoleAdd();
            }, error => {
                _this.setState({loading: false, error: error.error});
            });
        }
    }

    createCheckboxes() {
        var acElements = [];
        for (var key in this.props.role.access_control) {
            acElements.push(
                <div className="role-detail-ac-row" key={key}>
                    <div className="role-detail-ac-title">{Translator.get(key)}</div>
                    <Checkbox name={key} checked={this.selectedAc[key]} onCheck={this.onCheckBox.bind(this)}/> 
                </div>
            );
        }
        this.setState({acElements: acElements});
    }

    render() {
        var content;
        if (this.state.loading) {
            content = (<Popup><ActivityIndicator/></Popup>);
        }
        return (
            <div className="role-detail">
                <NavigationBar title={this.props.role.name} buttonsRight={
                    <div onClick={this.onCancel.bind(this)}>{Translator.get("cancel")}</div>
                } buttonsLeft={
                    <div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>
                }/>
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                {content}                
                <div className="role-detail-content">
                    <Input type="text" className="role-detail-input" 
                        value={this.state.roleName} 
                        onChange={this.onNameChange.bind(this)}
                        label={Translator.get("role_name_label")}>
                    </Input>
                    <div className="role-detail-ac">
                        {this.state.acElements}
                    </div>
                </div>
            </div>
        );
    }
}

export default RoleDetail;