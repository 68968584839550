import React, { Component } from 'react';
import './Profile.css'
import Api from '../../scripts/Api';
import NavigationBar from '../../../components/navigation-bar/NavigationBar';
import Translator from '../../scripts/Translator';
import Notification from './notifications/Notification';
import ProfileEdit from './profile-edit/ProfileEdit';

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            profile: {},
            view: "profile"
        };
    }

    componentDidMount() {
        this.loadProfile(600);
    }

    loadProfile(cache) {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.get("/profile", profile => {
            var bday = profile.member.birthdate.split("-");
            if (bday.length === 3) {
                profile.member.birthdateString = bday[2] + "." + bday[1] + "." + bday[0];
            }
            _this.user = profile.user;
            _this.setState({loading: false, profile: profile.member});
        }, e => {
            _this.setState({error: e.error, loading: false});
        }, cache);
    }

    onButtonClick() {
        this.setState({view: "edit"});
    }

    onEditNotifications() {
        this.setState({view: "notifications"});
    }

    onChildBack() {
        this.setState({view: "profile"});
    }

    onChildSaved() {
        this.loadProfile(0);
        this.setState({view: "profile"});
    }

    onLogout() {
        Api.post("/logout", {}, () => {
            Api.expiredCallback();
        });
    }

    render() {
        if (this.state.view === "notifications") {
            return <Notification context={this.props.context} user={this.user} onBack={this.onChildBack.bind(this)} onSaved={this.onChildSaved.bind(this)}></Notification>
        }
        else if (this.state.view === "edit") {
            return <ProfileEdit profile={this.state.profile} onBack={this.onChildBack.bind(this)} onSaved={this.onChildSaved.bind(this)}></ProfileEdit>
        }
        return (
            <div className="profile">
                <NavigationBar title={Translator.get("profile_title")}/>
                <div className="profile-content">
                    <div className="profile-label">{Translator.get("first_name")}</div>
                    <div className="profile-value">
                        {this.state.profile.first_name || "-"}
                    </div>
                    <div className="profile-label">{Translator.get("last_name")}</div>
                    <div className="profile-value">
                        {this.state.profile.last_name || "-"}
                    </div>
                    <div className="profile-label">{Translator.get("email")}</div>
                    <div className="profile-value">
                        {this.state.profile.email || "-"}
                    </div>
                    <div className="profile-label">{Translator.get("phone_number")}</div>
                    <div className="profile-value">
                        {this.state.profile.phone_number || "-"}
                    </div>
                    <div className="profile-label">{Translator.get("birthdate")}</div>
                    <div className="profile-value">
                        {this.state.profile.birthdateString || "-"}
                    </div>
                    <div className="profile-label">{Translator.get("address")}</div>
                    <div className="profile-value">
                        {this.state.profile.address || "-"}
                    </div>
                    <div className="profile-label">{Translator.get("zip_code")}</div>
                    <div className="profile-value">
                        {this.state.profile.zip_code || "-"}
                    </div>
                    <div className="profile-label">{Translator.get("locality")}</div>
                    <div className="profile-value">
                        {this.state.profile.locality || "-"}
                    </div>
                    <div className="profile-edit-button" onClick={this.onButtonClick.bind(this)}>{Translator.get("profile_edit")}</div>
                    <div className="profile-edit-button" onClick={this.onEditNotifications.bind(this)}>{Translator.get("email_notification_edit")}</div>
                    <div className="profile-logout-button" onClick={this.onLogout.bind(this)}>{Translator.get("logout")}</div>
                </div>
            </div>
        );
    }

}

export default Profile;