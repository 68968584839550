import React, { Component } from 'react';
import './Input.css';

class Input extends Component {

    render() {
        let propsClass = this.props.className || "";
        let appearance = this.props.appearance || "standard";
        let errorClass = this.props.error != null ? "error" : "";
        return (
            <div className={"input-wrapper " + appearance + " " + propsClass + " " + errorClass}>
                <input className={"input "} 
                    placeholder={this.props.placeholder || " "} 
                    value={this.props.value} 
                    onChange={this.props.onChange}
                    type={this.props.type}>
                </input>
                <div className="input-label">{this.props.label}</div>
            </div>
        );
    }
}

export default Input;