import React, { Component } from 'react';
import './SponsorDetail.css'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../scripts/Translator';
import Api from '../../../scripts/Api';
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import Input from '../../../../components/input/Input';

class SponsorDetail extends Component {

    constructor(props) {
        super(props);
        if (!props.sponsor) {
            this.state = {
                id: null,
                name: "",
                description: "",
                link: "",
                image: null
            };
        }
        else {
            this.state = {
                id: props.sponsor.id,
                name: props.sponsor.name,
                description: props.sponsor.description,
                link: props.sponsor.link,
                image: null
            };
        }
    }

    componentDidMount() {
        if (this.state.id) {
            // load image
            this.setState({imageLoading: true});
            var _this = this;
            Api.get("/sponsor/"+this.state.id+"/image", response => {
                response.blob().then(data => {
                    var image = URL.createObjectURL(data);
                    _this.setState({image: image, imageLoading: false});
                });
            }, () => {
                _this.setState({imageLoading: false});
            });
        }
    }

    onValueChanged(key, e) {
        var o = {};
        o[key] = e.target.value;
        this.setState(o);
    }

    onImageChanged(e) {
        var image = URL.createObjectURL(e.target.files[0]);
        this.selectedImage = e.target.files[0];
        this.setState({image: image});
    }

    onCancel() {
        this.props.onBack();
    }

    onSave() {
        this.setState({loading: true});
        var body = new FormData();
        body.append("description", this.state.description);
        body.append("name", this.state.name);
        body.append("image", this.selectedImage);
        body.append("link", this.state.link);
        if (this.state.id) {
            Api.putForm("/sponsor/"+this.state.id, body, () => {
                this.setState({loading: false});
                this.props.onSponsorAdd();
            }, e => {
                this.setState({loading: false, error: e.error});
            });
        }
        else {
            Api.postForm("/sponsor", body, () => {
                this.setState({loading: false});
                this.props.onSponsorAdd();
            }, e => {
                this.setState({loading: false, error: e.error});
            });
        }
    }

    onDelete() {
        if (this.state.id) {
            Api.delete("/sponsor/"+this.state.id, {}, () => {
                this.setState({loading: false});
                this.props.onSponsorAdd();
            }, e => {
                this.setState({loading: false, error: e.error});
            });
        }
    }

    render() {
        return (
            <div className="sponsor-detail">
                <NavigationBar title={Translator.get("sponsor_detail_title")} buttonsLeft={
                    <div onClick={this.onCancel.bind(this)}>{Translator.get("cancel")}</div>
                } buttonsRight={
                    <div onClick={this.onSave.bind(this)}>{Translator.get("save")}</div>
                }/>
                {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="sponsor-detail-content">
                    <div className="sponsor-detail-image">
                        <img className="sponsor-detail-image-object" src={this.state.image} alt=""/>
                        <input type="file" className="sponsor-detail-image-input" accept="image/png,image/jpeg" 
                            onChange={this.onImageChanged.bind(this)}>
                        </input>
                    </div>
                    <Input type="text" className="sponsor-detail-input" value={this.state.name} 
                        onChange={this.onValueChanged.bind(this, "name")}
                        label={Translator.get("name")}>
                    </Input>
                    <Input type="text" className="sponsor-detail-input" value={this.state.description} 
                        onChange={this.onValueChanged.bind(this, "description")}
                        label={Translator.get("sponsor_description")}>
                    </Input>
                    <Input type="text" className="sponsor-detail-input" value={this.state.link} 
                        onChange={this.onValueChanged.bind(this, "link")}
                        label={Translator.get("link")}>
                    </Input>
                    {this.state.id && 
                        <div onClick={this.onDelete.bind(this)} className="sponsor-detail-remove">{Translator.get("delete")}</div>}
                </div>
            </div>
        );
    }

}

export default SponsorDetail;