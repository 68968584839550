import React, { Component } from 'react';
import './SponsorsController.css'
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Translator from '../../../scripts/Translator';
import AddIcon from '../../../../images/add.png';
import BackIcon from '../../../../images/back.png';
import Popup from '../../../../components/popup/Popup';
import ActivityIndicator from '../../../../components/activity-indicator/ActivityIndicator';
import ListViewItem from '../../../../components/list-view/ListViewItem';
import Api from '../../../scripts/Api';
import SponsorDetail from './SponsorDetail';

class SponsorsController extends Component {

    constructor(props) {
        super(props);
        this.state = {view: "menu"};
    }

    componentDidMount() {
        this.loadSponsors(60*10);
    }

    loadSponsors(cache) {
        this.setState({loading: true, error: null});
        var _this = this;
        Api.get("/sponsor", body => {
            console.log(body);
            _this.sponsorElements = body.map((b, i) => <ListViewItem key={i} text={b.name} disclosureIndicator onClick={this.onSponsorClicked.bind(this, b)}/>);
            _this.setState({loading: false});
        }, error => {
            _this.setState({error: error.error, loading: false});
        }, cache);
    }

    onSponsorClicked(sponsor) {
        this.selectedSponsor = sponsor;
        this.setState({view: "detail"});
    }

    onBackClick() {
        this.props.onBack();
    }

    onChildBack() {
        this.setState({view: "menu"});
    }

    onAddClick() {
        this.selectedSponsor = null;
        this.setState({view: "detail"});
    }

    onSponsorAdd() {
        this.loadSponsors(0);
        this.setState({view: "menu"});
    }

    render() {
        if (this.state.view === "detail") {
            return <SponsorDetail sponsor={this.selectedSponsor} onBack={this.onChildBack.bind(this)} onSponsorAdd={this.onSponsorAdd.bind(this)}></SponsorDetail>
        }
        return (
            <div className="sponsors-controller">
                <NavigationBar title={Translator.get("sponsors_title")} buttonsRight={
                    <img src={AddIcon} alt="Add Sponsor" onClick={this.onAddClick.bind(this)}></img>
                } buttonsLeft={
                    <img src={BackIcon} alt="Back" onClick={this.onBackClick.bind(this)}></img>
                }/>
                {this.state.loading && <Popup><ActivityIndicator/></Popup>}
                <Popup closeButton={Translator.get("ok")}>{Translator.get(this.state.error)}</Popup>
                <div className="sponsors-controller-content">
                    {this.sponsorElements}
                </div>
            </div>
        );
    }

}

export default SponsorsController;