import React, { Component } from 'react';
import './EventAskReasonPopup.css'
import Popup from '../../../components/popup/Popup';
import Translator from '../../scripts/Translator';
import Input from '../../../components/input/Input';

class EventAskReasonPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reason: ""
        };
    }

    onReasonChange(e) {
        this.setState({reason: e.target.value});
    }

    onReasonSend() {
        this.props.onSendReason(this.state.reason);
    }

    onCancel() {
        this.props.onCancel();
    }

    render() {
        return <Popup>
            <div className="event-ask-reason-popup-title">{Translator.get("event_decline_title")}</div>
            <div className="event-ask-reason-popup-text">{Translator.get("event_decline_text")}</div>
            <Input type="text" className="event-ask-reason-popup-input" 
                value={this.state.reason} 
                onChange={this.onReasonChange.bind(this)}
                label={Translator.get("event_decline_label")}>
            </Input>
            <div className="event-ask-reason-buttons">
                <div className="event-ask-reason-ok" onClick={this.onReasonSend.bind(this)}>{Translator.get("save")}</div>
                <div className="event-ask-reason-cancel" onClick={this.onCancel.bind(this)}>{Translator.get("cancel")}</div>
            </div>            
        </Popup>;
    }

}

export default EventAskReasonPopup;