import React, { Component } from 'react';
import './Checkbox.css';

class Checkbox extends Component {

    onCheck() {
        if (this.props.onCheck) {
            this.props.onCheck(this.props.name);
        }
    }

    render() {
        if (this.props.checked) {
            return (
                <div className={"checkbox checked " + (this.props.className || "")} onClick={this.onCheck.bind(this)}>
                    <div className="checkbox-tick"></div>
                </div>
            );
        }
        return (
            <div className={"checkbox " + (this.props.className || "")} onClick={this.onCheck.bind(this)}>
            </div>
        );
    }
}

export default Checkbox;